import Http from "@/libraries/Http"; 

export default {
    apiVersion: 'v1', 
    RESTroute: 'qstatus',

    async taskStatus(job_id) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/task-status/${job_id}`); 
    },

    async createTask(name, payload = {}) {        
        return Http.post(`/${this.apiVersion}/${this.RESTroute}/create-job/${name}`, payload);        
    },
    
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {params: params}); 
    }, 
    
    
}
