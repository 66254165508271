<script>
import NewVersionModal from "@/components/NewVersionModal";
import RefreshPageMixin from "@/mixins/RefreshPageMixin";

export default {
    components: { NewVersionModal },
    mixins: [RefreshPageMixin],
    data() {
        return {};
    },
    created() {
        this.initVersionCheck();
    },
};
</script>
<template>
    <div>
        <router-view></router-view>        
        <new-version-modal
            v-if="hashChanged && isProd"
            @close="hashChanged = false"
        />
    </div>
</template>


