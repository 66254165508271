<script>
/** 
 * 
    apiPaginator: {
        sort: null,
        direction: null,
        page: null,
        limit: null,
    },
 */
export default {
    name: "VSorter",
    props: {
        paginator: {
            type: Object,
        },
        name: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
            description: "Disable v-sorter and show text only :D",
        },
    },
    data() {
        return {
            direction: {
                asc: "sort-amount-up-alt",
                desc: "sort-amount-down-alt",
            },
        };
    },
    methods: {
        flipDirection() {
            if (typeof this.paginator.direction == "null") {
                return "asc";
            } else if (this.paginator.direction == "desc") {
                return "asc";
            } else {
                return "desc";
            }
        },
    },
};
</script>
<template>
    <span class="v-sorter">
        <span
            v-if="disabled === false"
            class="m-click v-sorter__elem"
            @click="$emit('sort', { sort: name, direction: flipDirection() })"
        >
            <slot /> <i
                :class="`fal fa-${direction[paginator.direction]}`"
                v-if="paginator.direction && paginator.sort == name"
            >
            </i>
        </span>
        <span v-else>
            <slot />
        </span>
    </span>
</template>

<style lang="scss">
    .v-sorter {
        white-space: pre;        
    }
</style>