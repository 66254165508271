
// TODO: import <SERVICE> from '@/prototypes/<SERVICE_NAME>';

/* NOTE: <SERVICE_NAME> should be camelcased and prefixed by '$'. */

export default {
    install(Vue) {
        // TODO: inject(Vue, <SERVICE_NAME>, <SERVICE>);
    },
};
