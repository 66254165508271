<script>
export default {
    name: 'VTr'
}
</script>

<template>
    <tr v-on="$listeners">
        <slot />
    </tr>
</template>

