<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import RingLoader from "vue-spinner/src/RingLoader.vue";
import BounceLoader from "vue-spinner/src/BounceLoader.vue";


export default {
    name: "VLoader",
    components: {
        PulseLoader,
        RingLoader,
        BounceLoader,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: "#56b5cb",
        },
        loader: {
            type: String,
            default: "bounce"
        }
    },
};
</script>

<template>
    <div class="v-loader">
        <template v-if="loader == 'pulse'">
            <pulse-loader :loading="loading" :color="color"></pulse-loader>
        </template>
        <template v-else-if="loader == 'ring'">
            <ring-loader :loading="loading" :color="color"></ring-loader>
        </template>

        <template v-else-if="loader == 'bounce'">
            <bounce-loader :loading="loading" :color="color"></bounce-loader>
        </template>

        <template v-else-if="loader == 'mini'">
            <i class="fas fa-spinner fa-pulse"></i>
        </template>

        <template v-if="$slots.default">
            <div class="v-loader__slot_msg">
                <slot />
            </div>
        </template>
    </div>
</template>

<style lang="scss">
    .v-loader {
        display: flex; 
        align-items: center; 
        justify-content: center;
        flex-direction: column;

        &__slot_msg {
            margin-top: 15px;
            font-size: 18px;            
        }
    }
</style>