/*!

=========================================================
* Vue Argon Dashboard PRO - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import plugins from "@/plugins/plugins";
import prototypes from "@/plugins/prototypes";
import services from "@/services";
import filters from "@/filters";
import mixins from "@/mixins";
// router setup
import router from "@/routes/router";
import store from "@/store";
import i18n from './i18n';



/* Load plugins */
Vue.use(plugins);
Vue.use(services);
Vue.use(filters);
Vue.use(mixins);
Vue.use(prototypes);


/* 
router.beforeEach((to, from, next) => {
    to.meta.translatedTitle = i18n.t(to.meta.title) // meta.title is keypath of locale messages
    next()
});
*/

// plugin setup
Vue.use(DashboardPlugin);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  beforeCreate() {
    console.log("Before create");
  },
  render: h => h(App),
  i18n,
  router,
  store
});