<script>
import nl2br from "@/filters/nl2br";
export default {
    name: "VNewlines",
    props: {
        element: {
            type: String,
            default: "span"
        },
        text: {
            type: String,
            default: ""
        },
        useEmpty: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        nl(t) {
            if (this.useEmpty && (t === "" || t === null)) {
                return "---";
            } else {
                return nl2br(t);
            }
        }
    }
};
</script>

<template>
    <component :is="element" v-html="nl(text)"></component>
</template>

<style></style>
