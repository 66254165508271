

let instance = {
    pad(num, padSize = -2) {
        return ("0" + num).slice(padSize);
    },

    explode(secs) {
        secs = Math.abs(secs);
        var minutes = Math.floor(secs / 60);
        secs = secs % 60;
        var hours = Math.floor(minutes / 60)
        minutes = minutes % 60;
        return {
            'hours': this.pad(hours, 1),
            'minutes': this.pad(minutes),
            'seconds': this.pad(secs)

        };        
    },

    implode(hours, minutes, seconds) {
        hours = parseInt(hours); 
        minutes = parseInt(minutes); 
        seconds = parseInt(seconds); 

        return (hours*3600 + minutes*60 + seconds); 
    },

    hhmmss(secs) {
        let exp = this.explode(secs); 
        return `${exp.hours}:${exp.minutes}:${exp.seconds}`;
    },

    

};

export default instance;

