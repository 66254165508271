<script>
import QueueService from "@/services/QueueService";

export default {
    name: "VQueue",
    props: {
        name: {
            default: "",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        job_id: {},
        poll_interval: {
            type: Number,
            default: 1500,
        },
    },
    data() {
        return {
            polling: false,
        };
    },
    watch: {
        job_id: function (nid) {
            if (parseInt(nid) > 0 && this.polling === false) {
                this.polling = true;
                this.startPolling();
            }
        },
    },
    methods: {
        async startPolling() {
            try {
                const r = await QueueService.taskStatus(this.job_id);
                let task = r.data.data;

                if (task.ready) {
                    // ready
                    console.log("Queue:task-ready", task);
                    this.polling = false;
                    this.$emit("task-ready", task);
                } else {
                    // poll
                    console.log("Queue:poll again");
                    setTimeout(this.startPolling, this.poll_interval);
                }
            } catch (e) {
                this.$swal({
                    icon: "error",
                    title: "Queue fejl",
                    text:
                        "Copy paste hele teksten og kontakt supporten: " +
                        JSON.stringify(e),
                });
            }
        },
    },
};
</script>

<template>
    <modal
        size="lg"
        v-if="loading"
        :show="true"
        :showClose="false"
        class="queue-modal"
    >
        <template slot="header">
            {{ $t("queue.header") }}
        </template>

        <template slot="default">
            <div class="queue-modal__container">
                <h3 class="text-center">{{ $t("queue.task_in_progress") }}</h3>
                <v-loader :loading="loading" />

                <div v-if="name" class="queue-modal__container_name">
                    {{ name }}
                </div>
            </div>
        </template>
    </modal>
</template>

<style lang="scss">
.queue-modal {
    &__container {
        h3 {
            margin-bottom: 30px;
        }

        &_name {
            text-align: center;
            margin-top: 30px;
            padding-bottom: 15px;
        }
    }
}
</style>
