import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

const profileMenu = {
    path: "/profile",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.my_account")
    },
    children: [
        {
            path: "",
            name: "MyAccountPage",
            component: () => import("@/views/Users/VMyAccountPage"),
            meta: {
                title: i18n.t("title.my_account")
            }
        }
    ]
};

export default profileMenu;