<script>
import EffiTime from "@/libraries/EffiTime";
import Cloner from "@/libraries/Cloner";

export default {
    name: "VTripleTime",
    props: {
        value: {
            description: "v-model",
        },
        enableSeconds: {
            type: Boolean,
            default: false,
            description: "",
        },
        snapTo: {
            type: Number,
            default: 900,
            description: "Snap-to in seconds, default 15 min (900 sec)",
        },
        disabled: {
            default: false,
        },
        enableControls: {
            type: Boolean,
            default: false,
        },
        interval: {
            default: 900,
        },
        disableHover: {
            default: false,
            description: "Disable hover function for controls",
        },
        externalHover: {
            default: false,
        },
    },
    data() {
        return {
            error: {
                hours: false,
                minutes: false,
                seconds: false,
            },
            hover: false,
        };
    },
    computed: {
        timeHours: {
            get: function () {
                let tmpTime = EffiTime.explode(this.value);
                return tmpTime.hours;
            },
            set: function (v) {
                let newValue =
                    parseInt(v) * 3600 +
                    parseInt(this.timeMinutes) * 60 +
                    parseInt(this.timeSeconds);
                if (Number.isNaN(newValue)) {
                    this.error.hours = true;
                } else {
                    this.error.hours = false;
                    console.log("hours", newValue);
                    this.$emit("input", newValue);
                }
            },
        },
        timeMinutes: {
            get: function () {
                let tmpTime = EffiTime.explode(this.value);
                return tmpTime.minutes;
            },
            set: function (v) {
                let newValue =
                    parseInt(this.timeHours) * 3600 +
                    parseInt(v) * 60 +
                    parseInt(this.timeSeconds);
                if (Number.isNaN(newValue)) {
                    this.error.minutes = true;
                } else {
                    this.error.minutes = false;

                    // Do snapping if defined
                    // if (this.snapTo > 0) {
                    //     let remainder = newValue % this.snapTo;

                    //     if (remainder != 0) {
                    //         // snap minus remainder => what we are missing to the nearest snap
                    //         newValue = newValue + (this.snapTo - remainder);

                    //         let tmpTime = EffiTime.explode(newValue);
                    //         this.minutes = tmpTime.minutes;
                    //     }
                    // }

                    console.log("minutes", newValue);
                    this.$emit("input", newValue);
                }
            },
        },
        timeSeconds: {
            get: function () {
                let tmpTime = EffiTime.explode(this.value);
                return tmpTime.seconds;
            },
            set: function (v) {
                let newValue =
                    parseInt(this.timeHours) * 3600 +
                    parseInt(this.timeMinutes) * 60 +
                    parseInt(v);
                if (Number.isNaN(newValue)) {
                    this.error.seconds = true;
                } else {
                    this.error.seconds = false;
                    console.log("seconds", newValue);
                    this.$emit("input", newValue);
                }
            },
        },
    },
    created() {
        if (this.disableHover == true) {
            this.hover = true;
        }
    },
    methods: {
        handleMinuteBlur() {
            if (this.snapTo > 0) {
                let totalSeconds =
                    parseInt(this.timeHours) * 3600 +
                    parseInt(this.timeMinutes) * 60 +
                    parseInt(this.timeSeconds);

                let remainder = totalSeconds % this.snapTo;
                if (remainder !== 0) {
                    totalSeconds += this.snapTo - remainder;
                }

                let tmpTime = EffiTime.explode(totalSeconds);
                this.timeMinutes = tmpTime.minutes;
            }
        },
        modifyTime(amount) {
            let v;

            if (this.value === null || this.value === "") {
                v = 0;
            } else {
                v = parseInt(this.value);
            }

            if (v + amount < 0) {
                return false;
            }

            v = v + amount;
            this.$emit("input", v);
        },
        subtract() {
            if (!this.disabled) {
                this.modifyTime(-1 * this.interval);
            }
        },
        add() {
            if (!this.disabled) {
                this.modifyTime(this.interval);
            }
        },
        registerHover(hoverOnOff) {
            if (this.disableHover == false) {
                this.hover = hoverOnOff;
            }
        },
    },
};
</script>

<template>
    <div
        class="v-triple-time"
        :class="{ 'is-hover': hover, 'is-disabled': disabled }"
        @mouseenter="registerHover(true)"
        @mouseleave="registerHover(false)"
    >
        <template v-if="enableControls">
            <div
                class="v-triple-time__modifier v-triple-time__modifier--minus"
                :class="{ 'm-click': !disabled, 'm-na': disabled }"
                @click="subtract"
            >
                <i class="fas fa-minus fa-lg fa-fw"></i>
            </div>
        </template>
        <input
            type="text"
            class="v-triple-time__hours v-triple-time-vmodel"
            :class="{
                'has-error': error.hours,
            }"
            v-model="timeHours"
            :disabled="disabled"
        />:<input
            type="text"
            class="v-triple-time__minutes v-triple-time-vmodel"
            :class="{
                'has-error': error.minutes,
            }"
            v-model="timeMinutes"
            :disabled="disabled"
            @blur="handleMinuteBlur"
        /><template v-if="enableSeconds"
            >:<input
                type="text"
                class="v-triple-time__seconds v-triple-time-vmodel"
                :class="{
                    'has-error': error.seconds,
                }"
                v-model="timeSeconds"
                :disabled="disabled"
        /></template>
        <template v-if="enableControls">
            <div
                @click="add"
                class="v-triple-time__modifier v-triple-time__modifier--plus"
                :class="{ 'm-click': !disabled, 'm-na': disabled }"
            >
                <i class="fas fa-plus fa-lg fa-fw"></i>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
.v-triple-time {
    //border: 1px dotted blue;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.is-disabled {
        input {
            background-color: $gray-200;
        }
    }

    &-vmodel {
        // border: 1px solid red;
        // width: 50px;
        text-align: right;
        background: transparent;
        border: 1px solid $gray-300;
        font-size: 28px;

        @media (max-width: 1024px) {
            border: 1px solid $border-color;
        }
    }

    &:focus-within {
        .v-triple-time-vmodel {
            border: 1px solid $primary;
        }
    }

    &:hover {
        .v-triple-time-vmodel {
            border: 1px solid $primary;
        }
    }

    /* Modifiers */
    &__modifier {
        padding: 5px 5px 4px;
        line-height: 2rem;
        border-radius: 6px;
        border: 1px solid transparent;

        @media (max-width: 1024px) {
            border: 1px solid $border-color;
        }

        &:hover {
            background: $gray-100;
            //border: 1px solid $primary;

            i {
                color: $primary;
            }
        }

        i {
            color: $gray-500;
            font-weight: 400;
            font-size: 24px;
        }

        &--plus {
            margin-left: 5px;

            &:active {
                border: 1px solid $primary;
            }

            @media (max-width: 580px) {
                margin-left: 20px;
            }
        }

        &--minus {
            margin-right: 5px;

            &:active {
                border: 1px solid $primary;
            }

            @media (max-width: 580px) {
                margin-right: 20px;
            }
        }
    }

    input {
        outline: none;
        line-height: 1rem;
        font-weight: 400;
        color: $gray-900;
        &:focus {
            border: 1px solid $primary;
            //color: $primary;
        }
    }

    input.has-error {
        border: 1px solid $danger !important;
        border-radius: 6px !important;
        color: $danger !important;
    }

    .v-triple-time-vmodel {
        //border: 1px solid white;
        border-radius: 6px;
        background-color: white;
    }

    /* V-model details */
    &__hours {
        width: 50px;
        padding-right: 8px;
    }

    &__minutes {
        width: 50px;
        text-align: center;
    }

    &__seconds {
        text-align: center;
        margin-right: 30px;
    }
}
</style>
