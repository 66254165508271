<script>
export default {
    name: "VErrors",
    props: {
        errors: {
            description: "Errors from API",
        },
        text: {
            default: null
        },
    },
};
</script>

<template>
    <div class="v-errors">
        <div class="alert alert-danger">
            <template v-if="text">{{text}}</template>
            <template v-else>{{$t('errors.error_general')}}</template>
            <ul class="v-errors__list">
                <li v-for="(error, index) in errors"
                :key="index">
                    {{error}}
                </li>
            </ul>
        </div>
    </div>
</template>


<style lang="scss">
    .v-errors {
        &__list {
            margin-top: 10px;
            margin-bottom: 0px;            
        }
    }
</style>