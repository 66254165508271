<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
const FilePond = vueFilePond(FilePondPluginFileValidateSize);

import CustomModal from "@/components/CustomModal";
import TipTap from "@/components/Editor/TipTap";

import ActivityService from "@/services/ActivityService";
import TaskService from "@/services/TaskService";
import ProjectService from "@/services/ProjectService";
import UserService from "@/services/UserService";
import CustomerService from "@/services/CustomerService";
import Cloner from "@/libraries/Cloner";
import { EventBus } from "@/libraries/EventBus";
import DayAttrs from "@/libraries/DayAttrs";
import AppCfg from "@/mixins/AppCfg";
import ProjectMilestoneService from "@/services/ProjectMilestoneService";

/**
 * VTaskForm -> main modal for editing mytasks, project tasks & timer tasks
 */
export default {
    name: "VTaskModal",
    components: {
        CustomModal,
        TipTap,
        FilePond
    },
    mixins: [AppCfg],
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            }
        },
        taskId: {
            default: -1,
            description: "Task ID for editing existing"
        },

        enableUserEdit: {
            type: Boolean,
            default: true,
            description:
                "Enable/disable master switch for user change (ACL checks are still performed)"
        },
        prefillTaskDate: {
            default: "",
            description: "Works for empty:true -> set a field preset"
        },
        prefillCustomerId: {
            default: 0,
            description: "Works for empty:true -> set a field preset"
        },
        prefillProjectId: {
            default: 0,
            description: "Works for empty:true -> set a field preset"
        }
    },
    data() {
        return {
            loading: false,
            task: {
                id: null,
                user_id: null,
                customer_id: null,
                project_id: null,
                activity_id: null,
                task_date: null,
                time_reg: 0,
                details: null,
                internal_details: null,
                is_billable: null,
                project_milestone_id: null
            },
            editing: false,
            processing: false,
            hasInternalNote: false,
            errors: {},
            formErrors: [],
            internal_customer: false,
            enableAutomation: false,
            //
            customers: [],
            projects: [],
            activities: [],
            users: [],
            milestones: [],
            // Time registration
            ref_time_reg: null,
            inactivePlus: false,
            highlightData: [],
            customerLang: ""
        };
    },
    computed: {
        canSubmit: function() {
            // THC UNCOMMENT: overlay
            // return false;

            if (!this.task) {
                return false;
            }

            // Invoiced task is immutable
            if (this.editing == true && this.context.is_invoiced > 0) {
                return false;
            }

            // ACL - viewing not own
            // Now admin (no RW) and task user_id does not match me
            if (this.ACL_RW == false && this.task.user_id != this.getMyId) {
                return false;
            }

            // Logic for opening up when registering a new task
            if (
                this.task.customer_id > 0 && // customer set
                this.task.activity_id > 0 && // activity set
                (this.internal_customer == true || // internal customer does not need project_id
                    (this.internal_customer == false &&
                        this.task.project_id > 0))
            ) {
                return true;
            } else {
                return false;
            }
        },

        /**
         * Dynamically match current task status
         * is_closed
         * is_invoiced
         */
        isMutable: function() {
            // THC UNCOMMENT: when task is locked - locks date picker, +/- buttons and time
            //return false;

            if (!this.task) {
                return false;
            }

            if (this.context.is_invoiced || this.context.is_closed) {
                return false;
            }

            return true;
        },

        // Diff for time changes
        diffTimeReg: function() {
            return parseInt(this.task.time_reg) - parseInt(this.ref_time_reg);
        },
        // Enable project/activity (selected customer)
        disabledProjectActivity: function() {
            if (this.task.customer_id > 0) {
                return false;
            } else {
                return true;
            }
        },
        // Watch for customer change
        inputCustomer: function() {
            return this.task.customer_id;
        },
        inputProject: function() {
            return this.task.project_id;
        },
        // Today for init on the form
        today: function() {
            return dayjs().format("YYYY-MM-DD");
        },
        // Warning for future date
        futureDateSelected: function() {
            return dayjs(this.task.task_date).isAfter(this.today, "day");
        },
        canChangeUser: function() {
            if (!this.task) {
                return false;
            }

            let time_reg = parseInt(this.task.time_reg) || 0;

            if (this.$aclCan(this.$ACL.TIMEREG_USAGE) && time_reg == 0) {
                return true;
            } else {
                return false;
            }
        },
        isInternalTask: function() {
            if (this.task === null) {
                return false;
            }

            if (this.task.project_id > 0) {
                return false;
            } else {
                return true;
            }
        },
        contextAwareActivities: function() {
            if (this.task === null) {
                return [];
            }

            if (this.internal_customer) {
                return this.activities.internal;
            } else {
                return this.activities.customer;
            }

            return [];
        },
        ...mapGetters("auth", ["getMyId", "getAuthUser", "getIsAdmin"])
    },

    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.TIMEREG_MGMT);

        this.loading = true;

        await this.fetchCustomers();
        await this.fetchActivities();

        if (!this.context.empty) {
            this.editing = true;
            await this.fetchProjects(this.context.customer_id);

            this.task = Cloner.pickClone(this.context, [
                "id",
                "user_id",
                "customer_id",
                "project_id",
                "activity_id",
                "task_date",
                "time_reg",
                "details",
                "internal_details",
                "is_billable",
                "project_milestone_id"
            ]);

            await this.fetchMilestones(true);

            this.ref_time_reg = this.task.time_reg;
        } else {
            this.editing = false;
            this.ref_time_reg = 0;
            this.task.is_billable = 1;
            this.task.user_id = this.getMyId;

            // Create defaults
            if (this.prefillTaskDate !== "") {
                this.task["task_date"] = this.prefillTaskDate;
            } else {
                this.task["task_date"] = this.today;
            }

            if (this.prefillCustomerId > 0) {
                this.task["customer_id"] = this.prefillCustomerId;
            }

            if (this.prefillProjectId > 0) {
                this.task["project_id"] = this.prefillProjectId;
            }
        }

        // Fetch users if it's an admin
        if (this.ACL_RW) {
            await this.fetchUsers();
        }

        // Show/hide tiptap with internal note
        if (
            typeof this.task.internal_details == "string" &&
            this.task.internal_details.length > 0
        ) {
            this.hasInternalNote = true;
        }

        this.enableAutomation = true;
        // 2021-11-08 watcher must be initalized later
        // otherwise a race condition when editing causes
        // project & user to go awry
        this.$watch("inputCustomer", this.watchInputCustomer);
        // Watch project_id so that correct list of 
        // project milestones can be downloaded and the field populated
        this.$watch("inputProject", this.watchProject);
        this.loading = false;
    },
    methods: {
        async watchInputCustomer(newVal) {
            if (this.enableAutomation) {
                // Fetch projects for a given customer
                if (newVal > 0) {
                    // Fetch projects for a newly selected customer
                    // This method also fetches internal_customer flag value
                    console.log("inputCustomer watcher");
                    await this.fetchProjects(this.task.customer_id);
                    if (this.internal_customer == 1) {
                        this.task.is_billable = 0;
                    } else {
                        this.task.is_billable = 1;
                    }
                    // Reset these two
                    this.task.project_id = null;
                    this.task.activity_id = null;
                }
            }
        },
        /**
         * Control all form fields depending on the status
         */
        fieldDisabled(fieldName) {
            // When creating new -> no restrictions
            if (this.editing == false) {
                return false;
            }

            // The task is immutable when invoiced
            if (this.context.is_invoiced > 0) {
                return true;
            }

            // Allow only changes to details and internal_details
            if (this.context.is_closed == 1) {
                let fields = ["details", "internal_details"];

                if (fields.includes(fieldName)) {
                    console.log("field_name: ", fieldName, false);
                    return false;
                } else {
                    console.log("field_name: ", fieldName, true);
                    return true;
                }
            }

            return false;
        },

        async fetchAttrs(a) {
            this.highlightData = await DayAttrs.eventHandler(a);
        },
        /**
         * Save the form
         * @save -> saved and updated task is returned
         */
        async saveForm() {
            try {
                this.processing = true;
                let dbTask;

                if (this.editing) {
                    const r = await TaskService.PUT(this.task);
                    dbTask = r.data.data;
                } else {
                    const r = await TaskService.POST(this.task);
                    dbTask = r.data.data;
                }

                this.$notify({
                    message: this.$t("invoice_templates.data_saved"),
                    timeout: 5000,
                    icon: "far fa-check",
                    type: "success"
                });

                this.$emit("save", dbTask);
                EventBus.$emit("task-created", dbTask.id);
            } catch (e) {
                console.log(e);

                this.errors = e.response.data.errors;

                if ("is_closed" in this.errors) {
                    this.formErrors = this.errors.is_closed;
                } else if ("is_invoiced" in this.errors) {
                    this.formErrors = this.errors.is_invoiced;
                } else {
                    this.formErrors = [];
                }
                console.log(e.response.data.errors, "CC");
            }

            this.processing = false;
        },
        /**
         * Get customer dropdown
         */
        async fetchCustomers(loading = true) {
            try {
                this.loading = true;
                const r = await CustomerService.dropdown();
                this.customers = r.data.data;
            } catch (e) {}

            this.loading = loading;
        },
        /**
         * Fetch projects for a given customer and update internal_customer
         * flag
         */
        async fetchProjects(customer_id) {
            try {
                this.projects = [];
                const r = await ProjectService.GET_customerProjectsData(
                    customer_id
                );
                this.projects = r.data.data.projects;
                this.internal_customer = r.data.data.customer.internal_customer;
                this.customerLang = r.data.data.customer.lang;
            } catch (e) {
                console.log("fetchProjects & internal customer flag", e);
            }
        },
        /**
         * Fetch users
         * (only for admins)
         */
        async fetchUsers(loading = true) {
            try {
                this.loading = true;
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {}
            this.loading = loading;
        },
        /**
         * TBD: should be done user dependent
         */
        async fetchActivities(loading = true) {
            try {
                this.loading = true;
                const r = await ActivityService.GET_taskDropdown();
                this.activities = r.data.data;
            } catch (e) {}
            this.loading = loading;
        },
        /**
         * Watch changes to project_id
         * @param {*} newValue 
         * @param {*} oldValue 
         */
        watchProject(newValue, oldValue) {
            if (parseInt(newValue) > 0) {
                this.fetchMilestones();
            }
        },
        /**
         * Fetch milestones dropdown
         * @param {} editing 
         */
        async fetchMilestones(editing = false) {
            this.milestones = [];
            if (parseInt(this.task.project_id) > 0) {
                try {
                    const { data } = await ProjectMilestoneService.GET_dropdown(
                        this.task.project_id, 
                        {editing: this.task.project_milestone_id}
                    );
                    this.milestones = data.data;
                } catch (e) {
                    console.log("fMile e", e);
                }
            }
        },
        /**
         * OK: +/- buttons for time manipulation
         */
        modifyCurrentInterval(value) {
            this.task["time_reg"] =
                parseInt(value) * 60 + parseInt(this.task["time_reg"]);
        },
        /**
         * OK: helper method for deactivating minus btns
         */
        inactiveMinus(value) {
            let sub = Math.abs(parseInt(value) * 60);

            if (parseInt(this.task["time_reg"]) - sub < 0) {
                return true;
            } else {
                return false;
            }
        },

        /** REFACTOR */
        /**
         * Fetch activities for treeselect
         */

        filepondLabel() {
            let labelString = '<i class="far fa-paperclip"></i> ';

            labelString += this.$t("task.filepond_drag_and_drop_or") + " ";
            labelString +=
                '<span class="filepond--label-action">' +
                this.$t("task.filepond_browse") +
                "</span>";

            return labelString;
        },
        /**
         * Remove internal note (triggers a save)
         */
        removeInternalNote() {
            this.$swal({
                icon: "warning",
                text: this.$t("task.confirm_remove_internal_note"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async answer => {
                if (answer.isConfirmed) {
                    this.task.internal_details = "";
                    this.hasInternalNote = false;
                }
            });
        },
        /**
         * Close the modal - either pure close or sent back
         * DB refreshed task.
         */
        closeTaskModal() {
            if (this.isModified) {
                this.$emit("save", this.dbTask);
            } else {
                this.$emit("close");
            }
        }
    }
};
</script>

<template>
    <custom-modal
        :loading="loading"
        size="xl"
        :show="true"
        @close="closeTaskModal"
        class="v-task-modal v-task-form"
    >
        <template slot="header">
            <div class="v-task-form__header_container">
                <div class="v-task-form__header_elem-left">
                    <template v-if="task.is_closed">
                        {{ $t("task.view_and_edit_closed_task") }}
                    </template>
                    <template v-else-if="task.is_invoiced">
                        {{ $t("task.view_invoiced_task") }}
                    </template>
                    <template v-else>
                        <template v-if="editing">
                            {{ $t("task.edit_task") }}
                        </template>
                        <template v-else>
                            {{ $t("task.register_time") }}
                        </template>
                    </template>
                    <span class="text-muted ml-2">#{{ task.id }}</span>
                </div>
            </div>
        </template>

        <template slot="default">
            <FormulateForm
                :errors="errors"
                :form-errors="formErrors"
                v-model="task"
                @submit="saveForm"
                :debounce="100"
            >
                <div
                    class="v-task-form__container"
                    id="v-task-scrollable-content"
                >
                    <div class="v-task-form__left">
                        <div class="v-task-form__left-content">
                            <!-- All the funny changes -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="styled-field customer-name">
                                        <div class="styled-field__icon">
                                            <i
                                                class="fa-fw far fa-building"
                                            ></i>
                                        </div>
                                        <div class="styled-field__select">
                                            <FormulateInput
                                                type="fselect"
                                                :options="customers"
                                                name="customer_id"
                                                :placeholder="
                                                    $t(
                                                        'task.placeholder_customer'
                                                    )
                                                "
                                                validation="required"
                                                :validation-name="
                                                    $t('task.customer')
                                                "
                                                :disabled="
                                                    fieldDisabled('customer_id')
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="styled-field">
                                        <div class="styled-field__icon">
                                            <i
                                                class="fa-fw far fa-clipboard-list-check"
                                            ></i>
                                        </div>
                                        <div class="styled-field__select">
                                            <FormulateInput
                                                :disabled="
                                                    disabledProjectActivity ||
                                                        fieldDisabled(
                                                            'project_id'
                                                        )
                                                "
                                                type="fselect"
                                                :options="projects"
                                                name="project_id"
                                                :no-options="
                                                    $t(
                                                        'task.no_cases_available'
                                                    )
                                                "
                                                :placeholder="
                                                    $t(
                                                        'task.placeholder_project'
                                                    )
                                                "
                                                :validation-name="
                                                    $t('task.case')
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="styled-field">
                                        <div class="styled-field__icon">
                                            <i class="fa-fw far fa-tag"></i>
                                        </div>
                                        <div class="styled-field__select">
                                            <FormulateInput
                                                :disabled="
                                                    disabledProjectActivity ||
                                                        fieldDisabled(
                                                            'activity_id'
                                                        )
                                                "
                                                type="fselect"
                                                :options="
                                                    contextAwareActivities
                                                "
                                                name="activity_id"
                                                :placeholder="
                                                    $t(
                                                        'task.placeholder_activity'
                                                    )
                                                "
                                                validation="required"
                                                :validation-name="
                                                    $t('task.activity')
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="styled-field">
                                        <div class="styled-field__icon">
                                            <i class="fa-fw far fa-sign"></i>
                                        </div>
                                        <div class="styled-field__select">
                                            <FormulateInput
                                                :disabled="
                                                    fieldDisabled(
                                                        'project_milestone_id'
                                                    ) || milestones.length === 0
                                                "
                                                type="fselect"
                                                :options="milestones"
                                                name="project_milestone_id"
                                                :placeholder="
                                                    $t(
                                                        'task.placeholder_project_milestone'
                                                    )
                                                "
                                                :validation="
                                                    milestones.length > 0
                                                        ? 'required'
                                                        : ''
                                                "
                                                :validation-name="
                                                    $t('task.project_milestone')
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="styled-field">
                                        <div class="styled-field__icon">
                                            <i class="fa-fw far fa-user"></i>
                                        </div>
                                        <div
                                            class="styled-field__select"
                                            v-if="ACL_RW"
                                        >
                                            <FormulateInput
                                                type="fselect"
                                                :options="users"
                                                name="user_id"
                                                :placeholder="
                                                    $t('task.placeholder_user')
                                                "
                                                validation="required"
                                                :validation-name="
                                                    $t('task.user')
                                                "
                                                :disabled="
                                                    fieldDisabled('customer_id')
                                                "
                                            />
                                        </div>
                                        <div class="styled-field__text" v-else>
                                            {{ getAuthUser.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Editor content -->
                            <div class="row mt-3">
                                <div class="col">
                                    <FormulateInput
                                        type="textarea"
                                        name="details"
                                        :label="$t('task.details_label')"
                                        :help="
                                            $t('task.details_help_text') +
                                                (task.customer_id > 0
                                                    ? '. ' +
                                                      $t(
                                                          'task.details_lang_help',
                                                          {
                                                              lang: appCfgRead(
                                                                  'langs.' +
                                                                      customerLang
                                                              )
                                                          }
                                                      )
                                                    : '')
                                        "
                                        :placeholder="
                                            $t('task.placeholder_details')
                                        "
                                        :validation-name="
                                            $t('task.description')
                                        "
                                        :disabled="fieldDisabled('details')"
                                    />
                                </div>
                            </div>

                            <div class="row" v-if="hasInternalNote == false">
                                <div class="col internal-note">
                                    <div
                                        @click="hasInternalNote = true"
                                        class="text-primary mt-1 m-click"
                                    >
                                        <i class="far fa-plus"></i>
                                        {{ $t("task.add_internal_note") }}
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-show="hasInternalNote == true">
                                <div class="col">
                                    <h4 class="v-task-form__internal-note">
                                        {{ $t("task.internal_note") }}
                                    </h4>
                                    <tip-tap
                                        v-model.lazy="task.internal_details"
                                        :disabled="
                                            fieldDisabled('internal_details')
                                        "
                                    />
                                    <div
                                        @click="removeInternalNote"
                                        class="m-click text-warning mt-1 internal-note"
                                    >
                                        <i class="far fa-trash"></i>
                                        {{ $t("task.delete_internal_note") }}
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col">
                                    <file-pond
                                        name="test"
                                        allow-multiple="true"
                                        :label-idle="filepondLabel()"
                                        max-files="3"
                                        server="/v1/"
                                        credits=""
                                        v-if="false"
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- .__left-content -->
                    </div>

                    <div class="v-task-form__right">
                        <div class="v-task-form__right-content">
                            <div class="v-task-form__big-timer-container">
                                <!-- Time registration -->
                                <div class="v-time-reg">
                                    <div class="v-time-reg__current-date">
                                        <div
                                            class="v-time-reg__current-date_inner"
                                        >
                                            <date-picker
                                                class=""
                                                v-model="task.task_date"
                                                @update:from-page="fetchAttrs"
                                                :model-config="{
                                                    type: 'string',
                                                    mask: 'YYYY-MM-DD'
                                                }"
                                                :is-required="true"
                                                :attributes="highlightData"
                                                :popover="{
                                                    positionFixed: true,
                                                    visibility: 'focus',
                                                    keepVisibleOnInput: false,
                                                    transition: 'none',
                                                    showDelay: 1,
                                                    hideDelay: 1
                                                }"
                                            >
                                                <template
                                                    v-slot="{ togglePopover }"
                                                >
                                                    <span
                                                        class="m-click"
                                                        @click="
                                                            !fieldDisabled(
                                                                'task_date'
                                                            ) == true
                                                                ? togglePopover(
                                                                      {
                                                                          placement:
                                                                              'bottom'
                                                                      }
                                                                  )
                                                                : false
                                                        "
                                                    >
                                                        {{
                                                            $d(
                                                                new Date(
                                                                    task.task_date
                                                                ),
                                                                "fullDate"
                                                            )
                                                        }}
                                                    </span>
                                                </template>
                                            </date-picker>
                                        </div>
                                    </div>
                                    <!-- current date -->

                                    <div
                                        v-if="futureDateSelected"
                                        class="text-danger text-center"
                                    >
                                        <i
                                            class="fas fa-exclamation-triangle"
                                        ></i>
                                        {{
                                            $t(
                                                "time_i.warning_future_date_selected"
                                            )
                                        }}
                                    </div>
                                    <!-- date in text -->

                                    <!-- Time display/editing -->
                                    <!-- Current time display + edit -->
                                    <div class="v-time-reg__current-usage">
                                        <!-- <v-triple-time
                                                v-model="task.time_reg"
                                            /> -->
                                        <FormulateInput
                                            type="ftripletime"
                                            name="time_reg"
                                            :disabled="
                                                fieldDisabled('time_reg')
                                            "
                                            :enable-controls="true"
                                            :disable-hover="true"
                                        />
                                    </div>

                                    <div
                                        class="v-time-reg__diff-display"
                                        v-if="false"
                                    >
                                        <template v-if="diffTimeReg != 0">
                                            <span
                                                :class="{
                                                    'text-success':
                                                        diffTimeReg > 0,
                                                    'text-danger':
                                                        diffTimeReg < 0
                                                }"
                                            >
                                                <template
                                                    v-if="diffTimeReg > 0"
                                                >
                                                    <i class="far fa-plus"></i>
                                                </template>
                                                <template
                                                    v-if="diffTimeReg < 0"
                                                >
                                                    <i class="far fa-minus"></i>
                                                </template>

                                                {{ diffTimeReg | toTime }}
                                            </span>
                                        </template>
                                    </div>

                                    <!-- Plus/minus buttons -->
                                    <div class="v-time-reg__btns" v-if="false">
                                        <div class="v-time-reg__btns_left">
                                            <base-button
                                                class="v-time-reg__btn-modifier"
                                                :disabled="
                                                    inactiveMinus(-60) ||
                                                        fieldDisabled(
                                                            'time_reg'
                                                        )
                                                "
                                                type="white"
                                                @click="
                                                    modifyCurrentInterval(-60)
                                                "
                                                >-60</base-button
                                            >
                                            <base-button
                                                class="v-time-reg__btn-modifier"
                                                :disabled="
                                                    inactiveMinus(-30) ||
                                                        fieldDisabled(
                                                            'time_reg'
                                                        )
                                                "
                                                type="white"
                                                @click="
                                                    modifyCurrentInterval(-30)
                                                "
                                                >-30</base-button
                                            >
                                            <base-button
                                                class="v-time-reg__btn-modifier"
                                                :disabled="
                                                    inactiveMinus(-15) ||
                                                        fieldDisabled(
                                                            'time_reg'
                                                        )
                                                "
                                                type="white"
                                                @click="
                                                    modifyCurrentInterval(-15)
                                                "
                                                >-15</base-button
                                            >
                                        </div>
                                        <div class="v-time-reg__btns_right">
                                            <base-button
                                                class="v-time-reg__btn-modifier"
                                                :disabled="
                                                    fieldDisabled('time_reg')
                                                "
                                                :class="{
                                                    inactive: inactivePlus
                                                }"
                                                type="white"
                                                @click="
                                                    modifyCurrentInterval(15)
                                                "
                                                >+15</base-button
                                            >
                                            <base-button
                                                class="v-time-reg__btn-modifier"
                                                :disabled="
                                                    fieldDisabled('time_reg')
                                                "
                                                :class="{
                                                    inactive: inactivePlus
                                                }"
                                                type="white"
                                                @click="
                                                    modifyCurrentInterval(30)
                                                "
                                                >+30</base-button
                                            >
                                            <base-button
                                                class="v-time-reg__btn-modifier"
                                                :disabled="
                                                    fieldDisabled('time_reg')
                                                "
                                                @click="
                                                    modifyCurrentInterval(60)
                                                "
                                                :class="{
                                                    inactive: inactivePlus
                                                }"
                                                type="white"
                                                >+60</base-button
                                            >
                                        </div>
                                    </div>
                                    <!-- .Plus/minus buttons -->
                                    <!-- .Time display/editing -->

                                    <!-- Billable -->
                                    <div
                                        class="v-time-reg__billable"
                                        v-if="!internal_customer"
                                    >
                                        <div
                                            class="v-time-reg__billable--label"
                                        >
                                            {{ $t("task.is_billable") }}
                                        </div>
                                        <FormulateInput
                                            type="fswitch"
                                            name="is_billable"
                                            :disabled="
                                                fieldDisabled('is_billable') ||
                                                    !getIsAdmin
                                            "
                                        />
                                    </div>

                                    <!-- Save / cancel -->
                                    <div
                                        v-if="true"
                                        class="v-time-reg__master-buttons"
                                    >
                                        <base-button
                                            type="primary"
                                            size="lg"
                                            native-type="submit"
                                            :loading="processing"
                                            :disabled="!canSubmit"
                                        >
                                            <template v-if="editing">{{
                                                $t("task.action_save")
                                            }}</template>
                                            <template v-else>{{
                                                $t("task.action_create")
                                            }}</template>
                                        </base-button>
                                        <FormulateErrors />
                                        <template v-if="editing == true">
                                            <div
                                                v-if="
                                                    context.is_closed == 1 &&
                                                        context.is_invoiced == 0
                                                "
                                                class="v-time-reg__master-buttons--warn-locked text-warning"
                                            >
                                                <i class="far fa-lock"></i>
                                                {{
                                                    $t(
                                                        "task.this_task_is_closed"
                                                    )
                                                }}
                                            </div>
                                            <div
                                                v-if="context.is_invoiced > 0"
                                                class="v-time-reg__master-buttons--warn-invoiced text-danger"
                                            >
                                                <i
                                                    class="far fa-file-invoice-dollar"
                                                ></i>
                                                {{
                                                    $t(
                                                        "task.this_task_is_invoiced"
                                                    )
                                                }}
                                            </div>
                                        </template>
                                    </div>

                                    <!-- contd -->
                                </div>
                                <!-- .Time registration -->
                            </div>
                        </div>
                    </div>
                </div>
            </FormulateForm>
        </template>
    </custom-modal>
</template>

<style lang="scss">
.internal-note {
    font-size: 14px;
}

.customer-name {
    .styled-field__text {
        color: #7c8584;
    }
}
</style>
