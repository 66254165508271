<script>
export default {
    name: 'VTh'
}
</script>

<template>
    <th v-on="$listeners">
        <slot />
    </th>
</template>

