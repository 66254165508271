import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

const subscriptionsMenu = {
    path: "/subscriptions",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.subscriptions")
    },
    children: [
        {
            path: "index",
            name: "SubscriptionsIndex",
            component: () =>
                import(/* webpackChunkName: "subscriptions" */ "@/views/Subscriptions/VIndex"),
            meta: {
                title: i18n.t("title.subscriptions")
            },
        },
        {
            path: "products",
            name: "SubscriptionsProductIndex",
            component: () =>
                import(/* webpackChunkName: "subscriptions" */ "@/views/Subscriptions/VProducts"),
            meta: {
                title: i18n.t("title.subscription_products")
            },
        },
        {
            path: "mass-edit",
            name: "SubscriptionsMassEdit",
            component: () =>
                import(/* webpackChunkName: "subscriptions" */ "@/views/Subscriptions/VMassEdit"),
            meta: {
                title: i18n.t("title.mass_edit_subscriptions")
            },
        },

    ]
};

export default subscriptionsMenu;