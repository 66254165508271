import Http from "@/libraries/PureHttp";

export default {
    apiVersion: 'v1',    

    // Login moved to auth vuex module

    async logout() {
        return Http.post(`/${this.apiVersion}/logout`);
    },

    async login(username, password) {
        let form = new FormData();
        form.append('email', username);
        form.append('password', password);

        return Http.post(`/${this.apiVersion}/login`, form, {
            headers: {
                'content-type': "application/x-www-form-urlencoded"
            }
        });
    },

    async refreshMyAuthData() {
        return Http.get(`/${this.apiVersion}/users/refresh-my-auth-data`);
    },
}