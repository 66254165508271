<script>
import UserService from "@/services/UserService";
import VSelect from "vue-select";

export default {
    name: "VAvatar",
    components: {
        VSelect,
    },
    props: {
        name: {
            type: String,
            default: "",
            description: "Name to be showin in a popover",
        },
        image: {
            type: String,
            default: "",
            description: "URL to the avatar",
        },
        classes: {
            type: String,
            default: "",
        },
        elem: {
            type: String,
            default: "span",
            description: "What to use as container element",
        },
        placement: {
            type: String,
            default: "top",
            description: "Popper placement",
        },
        editable: {
            type: Boolean,
            default: false,
            description: "Whether change user widget is available",
        },
        ban: {
            type: Boolean,
            default: false,
            description: "Show ban/noban in conjunction with editable"
        },
        taskForm: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        image_url: function () {
            if (this.image) {
                return this.image;
            } else {
                return "/img/avatar.png";
            }
        },
        avatarClasses: function () {
            let classes = []; 

            classes.push(this.classes); 

            if (this.ban) {
                if (this.editable) {
                    classes.push("avatar-editable");
                } else {
                    classes.push("avatar-not-editable");
                }
            }

            return classes;
        }
    },
    data() {
        return {
            loading: false,
            editing: false,
            users: [],
        };
    },
    methods: {
        async fetchUsers() {
            try {
                this.loading = true;
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {
                console.log("fetchUsers exception", e);
            }
            this.loading = false;
        },
        setUser(u) {
            this.$emit("change-user", parseInt(u.value));
        },
        editUser() {
            if (this.editable) {
                this.editing = true;

                this.$nextTick(function () {
                    this.$refs["userSelect"].open = true;
                    this.$refs["userSelect"].searchEl.focus();
                });
            }
        },
    },
};
</script>

<template>
    <span>
        <div v-if="editing" :class="taskForm ? 'v-avatar-user-select-container-form' : 'v-avatar-user-select-container'">
            <v-select
                ref="userSelect"
                :clearable="false"
                :class="taskForm ? 'v-avatar-user-select-control-form' : 'v-avatar-user-select-control'"
                :options="users"
                @open="fetchUsers()"
                :loading="loading"
                @input="setUser"
                @close="editing = false"
            >
                <div slot="no-options">
                    <div
                        class="eff-timer-selector-wrapper__element__spinner"
                        v-if="loading"
                    >
                        <v-loader :loading="loading" />
                    </div>

                    <span v-else>{{ $t("timer.customer_select_none") }}</span>
                </div>
            </v-select>
        </div>
        <element
            :is="elem"
            class="avatar rounded-circle"
            :class="avatarClasses"
            @click="editUser"
        >
            <el-popover
                v-if="name.length > 0"
                :placement="placement"
                trigger="hover"
                :content="name"
                :visible-arrow="true"
            >
                <span slot="reference">
                    <img alt="Image placeholder" :src="image_url" />
                </span>
            </el-popover>
            <img alt="Image placeholder" :src="image_url" v-else />
        </element>
    </span>
</template>


<style lang="scss">
.v-avatar-user-select-container {
    background: white;
    width: 250px;
    position: absolute;
    z-index: 2004;
    right: 192px;
    top: 48px;

    .v-avatar-user-select-control {
        z-index: 2005;
    }
}

.v-avatar-user-select-container-form {
    background: white;
    width: 250px;
    position: absolute;
    z-index: 2004;
    left: 0px;
    top: 36px;

    .v-avatar-user-select-control-form {
        z-index: 2005;
    }
}
</style>