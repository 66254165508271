<script>
import VSelect from "vue-select";

export default {
    components: {
        VSelect,
    },
    props: {
        context: {
            type: Object,
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false
        }, 
        clearable: {
            type: Boolean,
            default: false
        }
    },    
    computed: {
        model: {
            set: function (v) {
                this.context.model = v;
            }, 
            get: function () {
                if (Array.isArray(this.context.model)) {
                    return this.context.model.map(x => String(x));
                } else if(this.context.model === null) {
                    return "";
                } else {
                    return String(this.context.model);
                }
            }
        }        
    },        
};
</script>

<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
    >
        <v-select
            class="vfm-multiselect-multi"
            v-bind="context.attributes"
            v-model="model"
            :clearable="clearable"
            :options="context.options"
            :reduce="(option) => option.value"
            @blur="context.blurHandler"            
            autocomplete="no"
            :multiple="multiple"
        >
            <div slot="no-options">
                <div class="eff-timer-selector-wrapper__element__spinner"></div>
            </div>
        </v-select>
    </div>
</template>


<style lang="scss">
.vfm-multiselect-multi {
    &.form-control {
        height: auto !important; 
    }

    border: none !important;
    //border: 1px solid red !important;
    padding: 0px !important;

    .vs__dropdown-toggle {
        border-radius: 4px !important;
        border-color: rgb(229, 230, 231);
        min-height: 46px;
        background-color: white;        
    }

    &.vs--open {
        .vs__dropdown-toggle {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            
        }
    }
}
</style>