<script>
export default {
    props: {
        lang: {
            type: String,
            required: true
        }
    },
    computed: {
        toIso() {
            if (this.lang === "da") {
                return "dk";
            } else if (this.lang === "en") {
                return "gb";
            }
        }
    }
};
</script>

<template>
    <flag :iso="toIso" />
</template>
