<script>
import NumberParser from "@/libraries/NumberParser";

export default {
    components: {},
    props: {
        context: {
            type: Object,
            required: true,
        },
        append: {
            type: String,
        },
        prepend: {
            type: String,
        },
    },                    
};
</script>

<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
    >
        <div class="input-group vss-currency">
            <div class="input-group-prepend" v-if="prepend">
                <span class="input-group-text vss-code">
                    <small class="font-weight-bold">{{ prepend }}</small></span
                >
            </div>
            <input
                type="text"
                v-model="context.model"
                v-bind="context.attributes"
                autocomplete="no"
                @blur="context.blurHandler"
            />
            <div class="input-group-append" v-if="append">
                <span class="input-group-text vss-code">
                    <small class="font-weight-bold">{{ append }}</small></span
                >
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.input-group-text.vss-currency {
    border: none !important;
    padding: 0 !important;

    input {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .vss-code {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
</style>