import VueFormulate from '@braid/vue-formulate';

import {
    en,
    da
} from '@braid/vue-formulate-i18n';

import FormulateRules from "@/libraries/FormulateRules";

// Formulate custom deps
import TextareaAutosize from "vue-textarea-autosize";
// Formulate plugins/customs
import MultiSelect from "@/components/Formulate/MultiSelect";
import FCurrency from "@/components/Formulate/FCurrency";
import FDecimal from "@/components/Formulate/FDecimal";
import FGroupDecimal from "@/components/Formulate/FGroupDecimal";
import FGroupText from "@/components/Formulate/FGroupText";
import FMonthDay from "@/components/Formulate/FMonthDay";
import FSearch from "@/components/Formulate/FSearch";
import FMultiline from "@/components/Formulate/FMultiline";
import FStyledSelect from "@/components/Formulate/FStyledSelect"; 
import FStyledInput from "@/components/Formulate/FStyledInput";
import FSelect from "@/components/Formulate/FSelect";
import FSwitch from "@/components/Formulate/FSwitch";
import FTripleTime from "@/components/Formulate/FTripleTime";
import FDatePicker from "@/components/Formulate/FDatePicker";

export default {
    install(Vue) {        

        Vue.use(VueFormulate, {
            plugins: [en, da],
            rules: FormulateRules,
            library: {
                styledinput: {
                    classification: 'input',
                    component: 'FStyledInput',
                    slotProps: {
                        component: ['icon']
                    }
                },
                styledselect: {
                    classification: 'select',
                    component: 'FStyledSelect',
                    slotProps: {
                        component: ['icon']
                    }
                },
                multiline: {
                    classification: 'textarea',
                    component: 'FMultiline'
                },
                fsearch: {
                    classification: 'text',
                    component: 'FSearch'
                },
                mday: {
                    classification: 'date',
                    component: 'FMonthDay',
                },
                groupdecimal: {
                    classification: 'text',
                    component: 'FGroupDecimal',
                    slotProps: {
                        component: ['prepend', 'append', 'precision', 'align']
                    }
                },
                grouptext: {
                    classification: 'text',
                    component: 'FGroupText',
                    slotProps: {
                        component: ['prepend', 'append']
                    }
                },
                multiselect: {
                    classification: 'select',
                    component: 'MultiSelect',
                    slotProps: {
                        component: ['multiple', 'clearable']
                    }
                },
                decimal: {
                    classification: 'text',
                    component: 'FDecimal',
                    slotProps: {
                        component: ['numberStyle']
                    }
                },
                currency: {
                    classification: 'text',
                    component: 'FCurrency',
                    slotProps: {
                        component: ['currency']
                    }
                },
                fselect: {
                    classification: 'select',
                    component: 'FSelect',   
                    slotProps: {
                        component: ['noOptions']
                    }                 
                },
                fswitch: {
                    classification: 'checkbox',
                    component: 'FSwitch',                    
                },
                ftripletime: {
                    classification: 'text',
                    component: 'FTripleTime',    
                    slotProps: {
                        component: ['enableControls', 'disableHover']
                    }                
                },
                fdatepicker: {
                    classification: 'text',
                    component: 'FDatePicker',    
                    slotProps: {
                        component: ['version']
                    }                
                },
            },
            classes: {
                outer: 'form-group vf-c',
                wrapper: '',
                label: 'form-control-label',
                element: '',
                input: 'form-control',
                inputHasErrors: 'is-invalid',
                help: 'help-text',
                errors: 'has-errors',
                error: 'error-entry'
            }
        });

        Vue.use(TextareaAutosize);
        Vue.component('MultiSelect', MultiSelect);        
        Vue.component('FCurrency', FCurrency);
        Vue.component('FDecimal', FDecimal);
        Vue.component('FGroupDecimal', FGroupDecimal);
        Vue.component('FGroupText', FGroupText);
        Vue.component('FMonthDay', FMonthDay);
        Vue.component('FSearch', FSearch);
        Vue.component('FMultiline', FMultiline);        
        Vue.component('FStyledSelect', FStyledSelect);        
        Vue.component('FStyledInput', FStyledInput);
        Vue.component('FSelect', FSelect);
        Vue.component('FSwitch', FSwitch);
        Vue.component('FTripleTime', FTripleTime);
        Vue.component('FDatePicker', FDatePicker)
    }
}