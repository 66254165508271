import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

/* TASKS */
const tasksMenu = {
    path: "/tasks",
    name: "Tasks",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.tasks")
    },
    children: [
        {
            path: "index",
            name: "TasksAllTasks",
            component: () =>
                import(
                    /* webpackChunkName: "tasks" */ "@/views/Tasks/VAllTasks"
                ),
            meta: {
                title: i18n.t("title.all_tasks")
            }
        },
        {
            path: "my-tasks",
            name: "TasksMyTasks",
            component: () =>
                import(
                    /* webpackChunkName: "tasks" */ "@/views/Tasks/VMyTasks"
                ),
            meta: {
                title: i18n.t("title.my_tasks")
            }
        }
    ]
};

export default tasksMenu;