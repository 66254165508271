import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";


const errorsMenu = {
    path: "/error",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.error")
    },
    children: [
        {
            path: "access-denied",
            name: "ErrorAccessDenied",
            component: () => import("@/views/Errors/VError"),
            meta: {
                title: i18n.t("title.error_no_access")
            }
        }
    ]
};

export default errorsMenu;