<script>
export default {
    name: "VWip",
    props: {
        title: {
            type: String,
            default: ""
        }
    },
    created() {
        console.log("WIP");
        console.log("$aclCan", this.$aclCan("test"));
        console.log("$aclCan", this.$aclCan(this.$ACL.USER_MGMT));
    }
};
</script>

<template>
    <card body-classes="px-0">
        <div class="pb-mg">
            <div class="nice-info">
                <i class="fas fa-construction fa-4x"></i>

                <h1 class="mt-3">WORK IN PROGRESS</h1>
                
                <h3 v-if="title">{{title}}</h3>

                <p v-if="$slots.default">
                    <slot />
                </p>
            </div>
        </div>
    </card>
</template>