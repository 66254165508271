import { pick, cloneDeep } from "lodash";

let instance = {
    clone(source) {
        return cloneDeep(source);
    },
    /**
     * lodash pick and clone in a smarty way. 
     * It's possible to pick and clone with deepClone on nested structured. 
     * 
     * Magic keys in fields: 
     * Arrays: key.[]
     * Objects: key.{}
     * 
     * @param {*} sourceObject 
     * @param {*} fields 
     */
    pickClone(sourceObject, fields) {
        let toPick = []; 
        let toClone = [];
        let clonedObject = {}; 

        fields.forEach( (v) => {
            
            if (v.includes(".[]")) {                
                toClone.push({
                    field: this.getField(v), 
                    emptyType: [] 
                });
            } else if (v.includes(".{}")) {                
                toClone.push({
                    field: this.getField(v), 
                    emptyType: {} 
                });
            } else {                
                toPick.push(v);
            }
        });         

        clonedObject = pick(sourceObject, toPick);

        if (toClone.length) {
            toClone.forEach( (el) => {
                if (typeof sourceObject[el.field] === "undefined") {
                    clonedObject[el.field] = el.emptyType; 
                } else {
                    clonedObject[el.field] = cloneDeep(sourceObject[el.field]);
                }
            }); 
        }

        return clonedObject; 
    },

    getField(field) {
        let spl = field.split("."); 
        return spl[0]; 
    }


};



export default instance;