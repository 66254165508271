<script>
import dayjs from "dayjs";
import ActivityService from "@/services/ActivityService";
import TaskService from "@/services/TaskService";
import Cloner from "@/libraries/Cloner";
import Messages from "@/mixins/Messages";

export default {
    name: "VSystemActivityForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
            description: "Context"
        }
    },
    mixins: [Messages],
    data() {
        return {
            loading: false,
            editing: false,
            activities: [],
            task: {
                id: null,
                activity_id: null,
                internal_details: null,
                task_date: null,
                time_reg: null,
                full_day: false,
                create_interval: false,
                interval_to: null
            },
            errors: {}
        };
    },
    computed: {
        today: function() {
            return dayjs().format("YYYY-MM-DD");
        },
        create_interval: function() {
            return this.task.create_interval;
        }
    },
    watch: {
        create_interval: function(n) {
            if (n == true) {
                this.task.full_day = true;
            }
        }
    },
    async created() {
        await this.fetchActivities(true);

        if (!this.context.empty) {
            this.editing = true;
            this.task = Cloner.pickClone(this.context, [
                "id",
                "activity_id",
                "internal_details",
                "task_date",
                "time_reg"
            ]);
            this.task.full_day = false;
        } else {
            this.task.task_date = this.today;
        }

        this.loading = false;
    },
    methods: {
        async fetchActivities(loading = false) {
            try {
                this.loading = true;
                const r = await ActivityService.GET_systemDropdown();
                this.activities = r.data.data;
            } catch (e) {
                console.log("fetchActivities exception", e);
            }

            this.loading = loading;
        },
        /**
         * Save form
         */
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await TaskService.PUT_systemTask(this.task);
                    this.$emit("save", r.data.data);
                    this.$msgSuccess(
                        this.$t("activity.notify_system_activity_edited")
                    );
                } else {
                    const r = await TaskService.POST_systemTask(this.task);
                    this.$msgSuccess(
                        this.$t("activity.notify_system_activity_created")
                    );
                    this.$emit("save", r.data.data);
                }

                this.$emit("close-refresh");
            } catch (e) {
                console.log(e);
                this.errors = e.response.data.errors;
            }
        },
        isFuture(date) {
            return dayjs(date).isAfter(this.today, "day");
        }
    }
};
</script>

<template>
    <modal
        :show="true"
        @close="$emit('close')"
        class="activity-modal"
        :loading="loading"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("task.edit_system_activity") }}
            </template>
            <template v-else>
                {{ $t("task.create_system_activity") }}
            </template>
        </template>

        <template slot="default">
            <FormulateForm v-model="task" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                        v-if="editing"
                            name="activity_id"
                            type="select"
                            :options="activities"
                            :label="$t('activity.type')"
                            :validation-name="$t('activity.type')"
                            :placeholder="$t('activity.placeholder_type')"
                            :disabled="editing"
                            validation="required"
                        />
                        <template v-else>
                            <div class="alert alert-danger" v-if="'activity_id' in errors">
                                {{ errors.activity_id.join(" ") }}
                            </div>
                        </template>
                    </div>
                    <div class="col-12 v-system-act__date">
                        <FormulateInput
                            name="task_date"
                            type="date"
                            :label="
                                task.create_interval
                                    ? $t('activity.date_from')
                                    : $t('activity.date')
                            "
                            :validation-name="$t('activity.date')"
                            :placeholder="$t('activity.placeholder_date')"
                            validation="required"
                        />
                        <FormulateInput
                            v-if="!editing && task.create_interval"
                            name="interval_to"
                            type="date"
                            :label="$t('activity.date_to')"
                            :validation-name="$t('activity.date')"
                            :placeholder="$t('activity.placeholder_date')"
                            validation="required"
                        />

                        <input
                            type="checkbox"
                            v-model="task.full_day"
                            :disabled="task.create_interval"
                        />
                        <span class="small ml-2">{{
                            $t("activity.whole_day")
                        }}</span>

                        <div v-if="!editing">
                            <input
                                type="checkbox"
                                v-model="task.create_interval"
                            />
                            <span class="small ml-2">{{
                                $t("activity.create_interval")
                            }}</span>
                        </div>

                        <div
                            v-if="isFuture(task.task_date)"
                            class="text-warning text-center mt-3"
                        >
                            <i class="fas fa-exclamation-circle"></i>
                            {{ $t("time_i.warning_future_date_selected") }}
                        </div>
                    </div>

                    <div class="col-12 mt-4 v-system-act__time-reg">
                        <FormulateInput
                            type="ftripletime"
                            name="time_reg"
                            :label="$t('activity.time')"
                            :enable-controls="true"
                            :disable-hover="true"
                            :disabled="task.full_day"
                        />
                    </div>
                    <div class="col-12">
                        <FormulateInput
                            v-if="false"
                            type="textarea"
                            name="details"
                            :label="$t('activity.note')"
                            :validation-name="$t('activity.note')"
                        />
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style lang="scss">
.v-system-act {
    &__date {
        .form-group {
            margin-bottom: 8px;
        }
    }

    &__time-reg {
        .v-triple-time {
            justify-content: center;
        }
    }
}
</style>
