import { map } from "lodash";

export default {
    methods: {
        $handleExceptionError(e, title) {
            console.log("HANDLE", e.response.data);
            if ("errors" in e.response.data) {
                let strings;

                if (!Array.isArray(e.response.data.errors)) {
                    /* Object */

                    strings = map(e.response.data.errors, function(v) {
                        console.log("CHECKER", v);
                        if (Array.isArray(v)) {
                            return v.join(".\n");
                        } else {
                            return v;
                        }
                    });
                } else {
                    strings = e.response.data.errors;
                }

                this.$swal({
                    icon: "error",
                    title: title,
                    text: strings.join(".\n")
                });
            } else {
                this.$swal({
                    icon: "error",
                    title: title,
                    text: this.$t("unknown_error")
                });
            }
        },

        $msgSuccess(message) {
            this.$notify({
                message: message,
                timeout: 5000,
                icon: "far fa-check",
                type: "success"
            });
        },
        $msgError(message) {
            this.$notify({
                message: message,
                timeout: 5000,
                icon: "far fa-check",
                type: "error"
            });
        },
        $askDeleteQuestion(questionText, asyncDeleteFunction, arg = null) {
            this.$swal({
                icon: "warning",
                text: questionText,
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    await asyncDeleteFunction(arg);
                }
            });
        }
    }
};
