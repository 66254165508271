import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

const travelPricesMenu = {
    path: "/travel-prices",
    name: "TravelPrices",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.travel_prices")
    },
    children: [
        {
            path: "",
            name: "TravelPricesIndex",
            component: () =>
                import(
                    /* webpackChunkName: "travels" */ "@/views/TravelPrices/VIndex"
                ),
            meta: {
                title: i18n.t("title.travel_prices")
            }
        }
    ]
};

export default travelPricesMenu;