import {
    mapGetters
} from "vuex";
import {
    get
} from "lodash";

export default {
    data() {
        return {
            appCfgLangs: {
                'da-DK': "Dansk",
                'en-GB': "English"
            }
        }
    },
    computed: {
        ...mapGetters("backend", ["appCfg"]),
        appCfgLangsSelect: function () {
            return this.appCfgHashToSelect(this.appCfgLangs);
        }
    },


    methods: {
        

        appCfgRead(path, toSelect = false) {
            if (toSelect == true) {
                return this.appCfgHashToSelect(get(this.appCfg, path));
            } else {
                return get(this.appCfg, path);
            }

        },

        appCfgHashToSelect(hash) {

            let arr = [];

            for (let key in hash) {

                arr.push({
                    label: hash[key],
                    value: key
                });
            }

            return arr;
        }
    }
};