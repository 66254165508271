import axios from 'axios';
import store from "@/store";
import router from "@/routes/router";

let instance = null;

if (!instance) {
    instance = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        withCredentials: true,
        maxContentLength: 999999,
        timeout: 30000,
    });
}

// Interceptor for AUTH
instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    if (error.response.status === 401 && error.response.config.url != "/v1/login") {
        store.dispatch('auth/logout');
        router.push('/login');
    }

    return Promise.reject(error);
})

export default instance;