var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('custom-modal',{staticClass:"v-task-modal v-task-form",attrs:{"loading":_vm.loading,"size":"xl","show":true},on:{"close":_vm.closeTaskModal}},[_c('template',{slot:"header"},[_c('div',{staticClass:"v-task-form__header_container"},[_c('div',{staticClass:"v-task-form__header_elem-left"},[(_vm.task.is_closed)?[_vm._v(" "+_vm._s(_vm.$t("task.view_and_edit_closed_task"))+" ")]:(_vm.task.is_invoiced)?[_vm._v(" "+_vm._s(_vm.$t("task.view_invoiced_task"))+" ")]:[(_vm.editing)?[_vm._v(" "+_vm._s(_vm.$t("task.edit_task"))+" ")]:[_vm._v(" "+_vm._s(_vm.$t("task.register_time"))+" ")]],_c('span',{staticClass:"text-muted ml-2"},[_vm._v("#"+_vm._s(_vm.task.id))])],2)])]),_c('template',{slot:"default"},[_c('FormulateForm',{attrs:{"errors":_vm.errors,"form-errors":_vm.formErrors,"debounce":100},on:{"submit":_vm.saveForm},model:{value:(_vm.task),callback:function ($$v) {_vm.task=$$v},expression:"task"}},[_c('div',{staticClass:"v-task-form__container",attrs:{"id":"v-task-scrollable-content"}},[_c('div',{staticClass:"v-task-form__left"},[_c('div',{staticClass:"v-task-form__left-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"styled-field customer-name"},[_c('div',{staticClass:"styled-field__icon"},[_c('i',{staticClass:"fa-fw far fa-building"})]),_c('div',{staticClass:"styled-field__select"},[_c('FormulateInput',{attrs:{"type":"fselect","options":_vm.customers,"name":"customer_id","placeholder":_vm.$t(
                                                    'task.placeholder_customer'
                                                ),"validation":"required","validation-name":_vm.$t('task.customer'),"disabled":_vm.fieldDisabled('customer_id')}})],1)])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"styled-field"},[_c('div',{staticClass:"styled-field__icon"},[_c('i',{staticClass:"fa-fw far fa-clipboard-list-check"})]),_c('div',{staticClass:"styled-field__select"},[_c('FormulateInput',{attrs:{"disabled":_vm.disabledProjectActivity ||
                                                    _vm.fieldDisabled(
                                                        'project_id'
                                                    ),"type":"fselect","options":_vm.projects,"name":"project_id","no-options":_vm.$t(
                                                    'task.no_cases_available'
                                                ),"placeholder":_vm.$t(
                                                    'task.placeholder_project'
                                                ),"validation-name":_vm.$t('task.case')}})],1)])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"styled-field"},[_c('div',{staticClass:"styled-field__icon"},[_c('i',{staticClass:"fa-fw far fa-tag"})]),_c('div',{staticClass:"styled-field__select"},[_c('FormulateInput',{attrs:{"disabled":_vm.disabledProjectActivity ||
                                                    _vm.fieldDisabled(
                                                        'activity_id'
                                                    ),"type":"fselect","options":_vm.contextAwareActivities,"name":"activity_id","placeholder":_vm.$t(
                                                    'task.placeholder_activity'
                                                ),"validation":"required","validation-name":_vm.$t('task.activity')}})],1)])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"styled-field"},[_c('div',{staticClass:"styled-field__icon"},[_c('i',{staticClass:"fa-fw far fa-sign"})]),_c('div',{staticClass:"styled-field__select"},[_c('FormulateInput',{attrs:{"disabled":_vm.fieldDisabled(
                                                    'project_milestone_id'
                                                ) || _vm.milestones.length === 0,"type":"fselect","options":_vm.milestones,"name":"project_milestone_id","placeholder":_vm.$t(
                                                    'task.placeholder_project_milestone'
                                                ),"validation":_vm.milestones.length > 0
                                                    ? 'required'
                                                    : '',"validation-name":_vm.$t('task.project_milestone')}})],1)])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"styled-field"},[_c('div',{staticClass:"styled-field__icon"},[_c('i',{staticClass:"fa-fw far fa-user"})]),(_vm.ACL_RW)?_c('div',{staticClass:"styled-field__select"},[_c('FormulateInput',{attrs:{"type":"fselect","options":_vm.users,"name":"user_id","placeholder":_vm.$t('task.placeholder_user'),"validation":"required","validation-name":_vm.$t('task.user'),"disabled":_vm.fieldDisabled('customer_id')}})],1):_c('div',{staticClass:"styled-field__text"},[_vm._v(" "+_vm._s(_vm.getAuthUser.name)+" ")])])])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col"},[_c('FormulateInput',{attrs:{"type":"textarea","name":"details","label":_vm.$t('task.details_label'),"help":_vm.$t('task.details_help_text') +
                                            (_vm.task.customer_id > 0
                                                ? '. ' +
                                                  _vm.$t(
                                                      'task.details_lang_help',
                                                      {
                                                          lang: _vm.appCfgRead(
                                                              'langs.' +
                                                                  _vm.customerLang
                                                          )
                                                      }
                                                  )
                                                : ''),"placeholder":_vm.$t('task.placeholder_details'),"validation-name":_vm.$t('task.description'),"disabled":_vm.fieldDisabled('details')}})],1)]),(_vm.hasInternalNote == false)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col internal-note"},[_c('div',{staticClass:"text-primary mt-1 m-click",on:{"click":function($event){_vm.hasInternalNote = true}}},[_c('i',{staticClass:"far fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("task.add_internal_note"))+" ")])])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasInternalNote == true),expression:"hasInternalNote == true"}],staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h4',{staticClass:"v-task-form__internal-note"},[_vm._v(" "+_vm._s(_vm.$t("task.internal_note"))+" ")]),_c('tip-tap',{attrs:{"disabled":_vm.fieldDisabled('internal_details')},model:{value:(_vm.task.internal_details),callback:function ($$v) {_vm.$set(_vm.task, "internal_details", $$v)},expression:"task.internal_details"}}),_c('div',{staticClass:"m-click text-warning mt-1 internal-note",on:{"click":_vm.removeInternalNote}},[_c('i',{staticClass:"far fa-trash"}),_vm._v(" "+_vm._s(_vm.$t("task.delete_internal_note"))+" ")])],1)]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col"},[(false)?_c('file-pond',{attrs:{"name":"test","allow-multiple":"true","label-idle":_vm.filepondLabel(),"max-files":"3","server":"/v1/","credits":""}}):_vm._e()],1)])])]),_c('div',{staticClass:"v-task-form__right"},[_c('div',{staticClass:"v-task-form__right-content"},[_c('div',{staticClass:"v-task-form__big-timer-container"},[_c('div',{staticClass:"v-time-reg"},[_c('div',{staticClass:"v-time-reg__current-date"},[_c('div',{staticClass:"v-time-reg__current-date_inner"},[_c('date-picker',{attrs:{"model-config":{
                                                type: 'string',
                                                mask: 'YYYY-MM-DD'
                                            },"is-required":true,"attributes":_vm.highlightData,"popover":{
                                                positionFixed: true,
                                                visibility: 'focus',
                                                keepVisibleOnInput: false,
                                                transition: 'none',
                                                showDelay: 1,
                                                hideDelay: 1
                                            }},on:{"update:from-page":_vm.fetchAttrs},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var togglePopover = ref.togglePopover;
return [_c('span',{staticClass:"m-click",on:{"click":function($event){!_vm.fieldDisabled(
                                                            'task_date'
                                                        ) == true
                                                            ? togglePopover(
                                                                  {
                                                                      placement:
                                                                          'bottom'
                                                                  }
                                                              )
                                                            : false}}},[_vm._v(" "+_vm._s(_vm.$d( new Date( _vm.task.task_date ), "fullDate" ))+" ")])]}}]),model:{value:(_vm.task.task_date),callback:function ($$v) {_vm.$set(_vm.task, "task_date", $$v)},expression:"task.task_date"}})],1)]),(_vm.futureDateSelected)?_c('div',{staticClass:"text-danger text-center"},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" "+_vm._s(_vm.$t( "time_i.warning_future_date_selected" ))+" ")]):_vm._e(),_c('div',{staticClass:"v-time-reg__current-usage"},[_c('FormulateInput',{attrs:{"type":"ftripletime","name":"time_reg","disabled":_vm.fieldDisabled('time_reg'),"enable-controls":true,"disable-hover":true}})],1),(false)?_c('div',{staticClass:"v-time-reg__diff-display"},[(_vm.diffTimeReg != 0)?[_c('span',{class:{
                                                'text-success':
                                                    _vm.diffTimeReg > 0,
                                                'text-danger':
                                                    _vm.diffTimeReg < 0
                                            }},[(_vm.diffTimeReg > 0)?[_c('i',{staticClass:"far fa-plus"})]:_vm._e(),(_vm.diffTimeReg < 0)?[_c('i',{staticClass:"far fa-minus"})]:_vm._e(),_vm._v(" "+_vm._s(_vm._f("toTime")(_vm.diffTimeReg))+" ")],2)]:_vm._e()],2):_vm._e(),(false)?_c('div',{staticClass:"v-time-reg__btns"},[_c('div',{staticClass:"v-time-reg__btns_left"},[_c('base-button',{staticClass:"v-time-reg__btn-modifier",attrs:{"disabled":_vm.inactiveMinus(-60) ||
                                                    _vm.fieldDisabled(
                                                        'time_reg'
                                                    ),"type":"white"},on:{"click":function($event){return _vm.modifyCurrentInterval(-60)}}},[_vm._v("-60")]),_c('base-button',{staticClass:"v-time-reg__btn-modifier",attrs:{"disabled":_vm.inactiveMinus(-30) ||
                                                    _vm.fieldDisabled(
                                                        'time_reg'
                                                    ),"type":"white"},on:{"click":function($event){return _vm.modifyCurrentInterval(-30)}}},[_vm._v("-30")]),_c('base-button',{staticClass:"v-time-reg__btn-modifier",attrs:{"disabled":_vm.inactiveMinus(-15) ||
                                                    _vm.fieldDisabled(
                                                        'time_reg'
                                                    ),"type":"white"},on:{"click":function($event){return _vm.modifyCurrentInterval(-15)}}},[_vm._v("-15")])],1),_c('div',{staticClass:"v-time-reg__btns_right"},[_c('base-button',{staticClass:"v-time-reg__btn-modifier",class:{
                                                inactive: _vm.inactivePlus
                                            },attrs:{"disabled":_vm.fieldDisabled('time_reg'),"type":"white"},on:{"click":function($event){return _vm.modifyCurrentInterval(15)}}},[_vm._v("+15")]),_c('base-button',{staticClass:"v-time-reg__btn-modifier",class:{
                                                inactive: _vm.inactivePlus
                                            },attrs:{"disabled":_vm.fieldDisabled('time_reg'),"type":"white"},on:{"click":function($event){return _vm.modifyCurrentInterval(30)}}},[_vm._v("+30")]),_c('base-button',{staticClass:"v-time-reg__btn-modifier",class:{
                                                inactive: _vm.inactivePlus
                                            },attrs:{"disabled":_vm.fieldDisabled('time_reg'),"type":"white"},on:{"click":function($event){return _vm.modifyCurrentInterval(60)}}},[_vm._v("+60")])],1)]):_vm._e(),(!_vm.internal_customer)?_c('div',{staticClass:"v-time-reg__billable"},[_c('div',{staticClass:"v-time-reg__billable--label"},[_vm._v(" "+_vm._s(_vm.$t("task.is_billable"))+" ")]),_c('FormulateInput',{attrs:{"type":"fswitch","name":"is_billable","disabled":_vm.fieldDisabled('is_billable') ||
                                                !_vm.getIsAdmin}})],1):_vm._e(),(true)?_c('div',{staticClass:"v-time-reg__master-buttons"},[_c('base-button',{attrs:{"type":"primary","size":"lg","native-type":"submit","loading":_vm.processing,"disabled":!_vm.canSubmit}},[(_vm.editing)?[_vm._v(_vm._s(_vm.$t("task.action_save")))]:[_vm._v(_vm._s(_vm.$t("task.action_create")))]],2),_c('FormulateErrors'),(_vm.editing == true)?[(
                                                _vm.context.is_closed == 1 &&
                                                    _vm.context.is_invoiced == 0
                                            )?_c('div',{staticClass:"v-time-reg__master-buttons--warn-locked text-warning"},[_c('i',{staticClass:"far fa-lock"}),_vm._v(" "+_vm._s(_vm.$t( "task.this_task_is_closed" ))+" ")]):_vm._e(),(_vm.context.is_invoiced > 0)?_c('div',{staticClass:"v-time-reg__master-buttons--warn-invoiced text-danger"},[_c('i',{staticClass:"far fa-file-invoice-dollar"}),_vm._v(" "+_vm._s(_vm.$t( "task.this_task_is_invoiced" ))+" ")]):_vm._e()]:_vm._e()],2):_vm._e()])])])])])])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }