

export default function toTime (seconds) {    
  return hhmmss(seconds)
}

function pad(num, padSize = -2) {
  return ("0"+num).slice(padSize);
}
  
function hhmmss(secs) {  
  secs = Math.abs(secs); 
  var minutes = Math.floor(secs / 60);
  secs = secs%60;
  var hours = Math.floor(minutes/60)
  minutes = minutes%60;
  //return `${pad(hours, 1)}:${pad(minutes)}:${pad(secs)}`;  
  return `${pad(hours, 1)}:${pad(minutes)}`;  
}
  
  