var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-tiptap"},[(_vm.editor)?_c('div',{staticClass:"v-tiptap__menu"},[_c('button',{staticClass:"tt-btn",class:{ 'is-active': _vm.editor.isActive('bold') },on:{"click":function($event){_vm.editor.chain().focus().toggleBold().run()}}},[_c('i',{staticClass:"fa-bold",class:_vm.iconSubclass})]),_c('button',{staticClass:"tt-btn",class:{ 'is-active': _vm.editor.isActive('italic') },on:{"click":function($event){_vm.editor.chain().focus().toggleItalic().run()}}},[_c('i',{staticClass:"fa-italic",class:_vm.iconSubclass})]),_c('button',{staticClass:"tt-btn",class:{ 'is-active': _vm.editor.isActive('strike') },on:{"click":function($event){_vm.editor.chain().focus().toggleStrike().run()}}},[_c('i',{staticClass:"fa-strikethrough",class:_vm.iconSubclass})]),_c('button',{staticClass:"tt-btn",class:{ 'is-active': _vm.editor.isActive('code') },on:{"click":function($event){_vm.editor.chain().focus().toggleCode().run()}}},[_c('i',{staticClass:"fa-code",class:_vm.iconSubclass})]),(false)?_c('button',{staticClass:"tt-btn",on:{"click":function($event){_vm.editor.chain().focus().unsetAllMarks().run()}}},[_vm._v(" clear marks ")]):_vm._e(),(false)?_c('button',{on:{"click":function($event){_vm.editor.chain().focus().clearNodes().run()}}},[_vm._v(" clear nodes ")]):_vm._e(),(false)?_c('button',{class:{ 'is-active': _vm.editor.isActive('paragraph') },on:{"click":function($event){_vm.editor.chain().focus().setParagraph().run()}}},[_vm._v(" paragraph ")]):_vm._e(),_c('button',{staticClass:"tt-btn",class:{
                'is-active': _vm.editor.isActive('heading', { level: 1 }),
            },on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 1 }).run()}}},[_c('i',{staticClass:"fa-h1",class:_vm.iconSubclass})]),_c('button',{staticClass:"tt-btn",class:{
                'is-active': _vm.editor.isActive('heading', { level: 2 }),
            },on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 2 }).run()}}},[_c('i',{staticClass:"fa-h2",class:_vm.iconSubclass})]),_c('button',{staticClass:"tt-btn",class:{
                'is-active': _vm.editor.isActive('heading', { level: 3 }),
            },on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 3 }).run()}}},[_c('i',{staticClass:"fa-h3",class:_vm.iconSubclass})]),_c('button',{staticClass:"tt-btn",class:{
                'is-active': _vm.editor.isActive('paragraph'),
            },on:{"click":function($event){_vm.editor.chain().focus().setParagraph().run()}}},[_c('i',{staticClass:"fa-paragraph",class:_vm.iconSubclass})]),_c('button',{staticClass:"tt-btn",class:{ 'is-active': _vm.editor.isActive('taskList') },on:{"click":function($event){_vm.editor.chain().focus().toggleTaskList().run()}}},[_c('i',{staticClass:"fa-tasks",class:_vm.iconSubclass})]),_c('button',{staticClass:"tt-btn",class:{ 'is-active': _vm.editor.isActive('bulletList') },on:{"click":function($event){_vm.editor.chain().focus().toggleBulletList().run()}}},[_c('i',{staticClass:"fa-list-ul",class:_vm.iconSubclass})]),_c('button',{staticClass:"tt-btn",class:{ 'is-active': _vm.editor.isActive('orderedList') },on:{"click":function($event){_vm.editor.chain().focus().toggleOrderedList().run()}}},[_c('i',{staticClass:"fa-list-ol",class:_vm.iconSubclass})]),(false)?_c('button',{class:{ 'is-active': _vm.editor.isActive('codeBlock') },on:{"click":function($event){_vm.editor.chain().focus().toggleCodeBlock().run()}}},[_vm._v(" code block ")]):_vm._e(),_c('button',{staticClass:"tt-btn",class:{ 'is-active': _vm.editor.isActive('blockquote') },on:{"click":function($event){_vm.editor.chain().focus().toggleBlockquote().run()}}},[_c('i',{staticClass:"fa-quote-right",class:_vm.iconSubclass})]),_c('button',{staticClass:"tt-btn",class:{ iconSubclass: true },on:{"click":function($event){_vm.editor.chain().focus().undo().run()}}},[_c('i',{staticClass:"fa-undo",class:_vm.iconSubclass})]),_c('button',{staticClass:"tt-btn",class:{ iconSubclass: true },on:{"click":function($event){_vm.editor.chain().focus().redo().run()}}},[_c('i',{staticClass:"fa-redo",class:_vm.iconSubclass})])]):_vm._e(),_c('div',{staticClass:"v-tiptap__editor",on:{"click":_vm.focusEditor}},[_c('editor-content',{attrs:{"editor":_vm.editor}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }