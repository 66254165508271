import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

/* PROJECTS (CASES) */
const projectsMenu = {
    path: "/projects",
    name: "Projects",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.cases")
    },
    children: [
        {
            path: "index",
            name: "ProjectsIndex",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/Projects/VIndex"
                ),
            meta: {
                title: i18n.t("title.all_cases")
            }
        },
        {
            path: "reopen",
            name: "ProjectsReopenDay",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/Projects/VReopen"
                ),
            meta: {
                title: i18n.t("title.reopen_day")
            }
        },
        {
            path: "my-overview",
            name: "ProjectsMyCases",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/Projects/VMyCases"
                ),
            meta: {
                title: i18n.t("title.my_cases_overview")
            }
        },
        {
            path: "my-invoices",
            name: "ProjectsInvoicingReportResponsible",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/Reports/VInvoicesResponsible"
                ),
            meta: {
                title: i18n.t("title.my_cases_overview")
            }
        },
        {
            path: "full-overview",
            name: "ProjectsFullCases",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/Reports/VAllCases"
                ),
            meta: {
                title: i18n.t("title.all_cases_overview")
            }
        },
        {
            path: "invoicing-report",
            name: "ProjectsInvoicingReport",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/Reports/VInvoices"
                ),
            meta: {
                title: i18n.t("title.projects_invoicing_report")
            }
        },
        {
            path: "view/:project_id",
            component: () =>
                import(
                    /* webpackChunkName: "projects" */ "@/views/Projects/VProjectView"
                ),
            props: true,
            children: [
                {
                    path: "stats",
                    name: "ProjectViewStats",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VStats"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "contract-stats",
                    name: "ProjectViewContractStats",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VContractStats"
                            )
                    },
                    meta: {
                        title: i18n.t("title.contract_statistics")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "tasks",
                    name: "ProjectViewTasks",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VTasks"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "task-manager",
                    name: "ProjectViewPricer",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VPricer"
                            )
                    },
                    meta: {
                        title: i18n.t("title.task_manager")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "expenses",
                    name: "ProjectViewExpenses",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VExpenses"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "transport",
                    name: "ProjectViewTransport",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VTransport"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "invoicing",
                    name: "ProjectViewInvoicing",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VInvoicing"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "invoice/:invoice_id",
                    name: "ProjectViewInvoice",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VInvoice"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "settings",
                    name: "ProjectViewSettings",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VSettings"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        project_tab: true
                    }
                },
                {
                    path: "milestones",
                    name: "ProjectViewMilestones",
                    components: {
                        project_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Projects/Tabs/VMilestones"
                            )
                    },
                    meta: {
                        title: i18n.t("title.milestones")
                    },
                    props: {
                        project_tab: true
                    }
                },
                
            ]
        }
    ]
};

export default projectsMenu;
