<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";


import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Placeholder from '@tiptap/extension-placeholder'

// https://pqina.nl/filepond/docs/patterns/api/server/
export default {
    props: {
        autoFocus: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
        },
    },
    components: {
        EditorContent,
    },
    watch: {
        value: function (v) {
            if (v === "") {
                this.editor.commands.clearContent(true);
            }
        },
    },
    data() {
        return {
            editor: null,
            iconSubclass: "far fa-fw",
        };
    },
    mounted() {
        this.editor = new Editor({
            content: this.value,
            extensions: [StarterKit, TaskList, TaskItem, 
            Placeholder.configure({
                placeholder: this.$t('tiptap.default_placeholder')
            })],
            onUpdate: ({ editor }) => {
                // The content has changed.
                const content = editor.getHTML();

                if (content == "<p></p>") {
                    this.$emit("input", "");
                } else {
                    this.$emit("input", content);
                }
            },
        });
    },
    beforeDestroy() {
        this.editor.destroy();
    },
    methods: {
        focusEditor() {
            this.editor.chain().focus().run();
        },
    },
};
</script>

<template>
    <div class="v-tiptap">
        <div v-if="editor" class="v-tiptap__menu">
            <button
                @click="editor.chain().focus().toggleBold().run()"
                class="tt-btn"
                :class="{ 'is-active': editor.isActive('bold') }"
            >
                <i :class="iconSubclass" class="fa-bold"></i>
            </button>
            <button
                @click="editor.chain().focus().toggleItalic().run()"
                class="tt-btn"
                :class="{ 'is-active': editor.isActive('italic') }"
            >
                <i :class="iconSubclass" class="fa-italic"></i>
            </button>
            <button
                @click="editor.chain().focus().toggleStrike().run()"
                class="tt-btn"
                :class="{ 'is-active': editor.isActive('strike') }"
            >
                <i :class="iconSubclass" class="fa-strikethrough"></i>
            </button>
            <button
                @click="editor.chain().focus().toggleCode().run()"
                class="tt-btn"
                :class="{ 'is-active': editor.isActive('code') }"
            >
                <i :class="iconSubclass" class="fa-code"></i>
            </button>
            <button
                v-if="false"
                @click="editor.chain().focus().unsetAllMarks().run()"
                class="tt-btn"
            >
                clear marks
            </button>
            <button
                @click="editor.chain().focus().clearNodes().run()"
                v-if="false"
            >
                clear nodes
            </button>
            <button
                @click="editor.chain().focus().setParagraph().run()"
                :class="{ 'is-active': editor.isActive('paragraph') }"
                v-if="false"
            >
                paragraph
            </button>
            <button
                @click="
                    editor.chain().focus().toggleHeading({ level: 1 }).run()
                "
                class="tt-btn"
                :class="{
                    'is-active': editor.isActive('heading', { level: 1 }),
                }"
            >
                <i :class="iconSubclass" class="fa-h1"></i>
            </button>
            <button
                @click="
                    editor.chain().focus().toggleHeading({ level: 2 }).run()
                "
                class="tt-btn"
                :class="{
                    'is-active': editor.isActive('heading', { level: 2 }),
                }"
            >
                <i :class="iconSubclass" class="fa-h2"></i>
            </button>
            <button
                @click="
                    editor.chain().focus().toggleHeading({ level: 3 }).run()
                "
                class="tt-btn"
                :class="{
                    'is-active': editor.isActive('heading', { level: 3 }),
                }"
            >
                <i :class="iconSubclass" class="fa-h3"></i>
            </button>

            <button
                @click="editor.chain().focus().setParagraph().run()"
                class="tt-btn"
                :class="{
                    'is-active': editor.isActive('paragraph'),
                }"
            >
                <i :class="iconSubclass" class="fa-paragraph"></i>
            </button>

            <button
                @click="editor.chain().focus().toggleTaskList().run()"
                class="tt-btn"
                :class="{ 'is-active': editor.isActive('taskList') }"
            >
                <i :class="iconSubclass" class="fa-tasks"></i>
            </button>

            <button
                @click="editor.chain().focus().toggleBulletList().run()"
                class="tt-btn"
                :class="{ 'is-active': editor.isActive('bulletList') }"
            >
                <i :class="iconSubclass" class="fa-list-ul"></i>
            </button>
            <button
                @click="editor.chain().focus().toggleOrderedList().run()"
                class="tt-btn"
                :class="{ 'is-active': editor.isActive('orderedList') }"
            >
                <i :class="iconSubclass" class="fa-list-ol"></i>
            </button>
            <button
                v-if="false"
                @click="editor.chain().focus().toggleCodeBlock().run()"
                :class="{ 'is-active': editor.isActive('codeBlock') }"
            >
                code block
            </button>
            <button
                @click="editor.chain().focus().toggleBlockquote().run()"
                class="tt-btn"
                :class="{ 'is-active': editor.isActive('blockquote') }"
            >
                <i :class="iconSubclass" class="fa-quote-right"></i>
            </button>
            <button
                @click="editor.chain().focus().undo().run()"
                class="tt-btn"
                :class="{ iconSubclass: true }"
            >
                <i :class="iconSubclass" class="fa-undo"></i>
            </button>
            <button
                @click="editor.chain().focus().redo().run()"
                class="tt-btn"
                :class="{ iconSubclass: true }"
            >
                <i :class="iconSubclass" class="fa-redo"></i>
            </button>
        </div>
        <div class="v-tiptap__editor" @click="focusEditor">
            <editor-content :editor="editor"  />
        </div>
    </div>
</template>

<style lang="scss" scoped>
::v-deep {
  /* Basic editor styles */
  .ProseMirror {
    > * + * {
      margin-top: 0.75em;
    }
  }

  /* Placeholder (at the top) */
  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #ced4da;
    pointer-events: none;
    height: 0;
  }

  /* Placeholder (on every new line) */
  /*.ProseMirror p.is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #ced4da;
    pointer-events: none;
    height: 0;
  }*/
}
</style>

<style lang="scss">
.v-tiptap {
    border: 1px solid $border-color;
    border-radius: 6px;

    &__menu {
        padding: 6px 10px 6px 10px;
        border-bottom: 1px solid $border-color;

        .tt-btn {
            border: 1px solid white;
            border-radius: 6px;
            margin-right: 6px;
            color: $gray-600;
            background-color: white;

            i {
                font-size: 14px;
            }

            &:last-child {
                margin-right: 0px;
            }

            &.is-active {
                background-color: $gray-300;
            }

            &:hover {
            }
        }
    }

    &__editor {
        padding: 10px;
        min-height: 140px;
        * {
            outline: none;
        }
        p {
            font-weight: 400;
            font-size: 15px;
        }
    }
}

ul[data-type="taskList"] {
    list-style: none;
    padding: 0;

    li {
        display: flex;
        align-items: center;

        > label {
            flex: 0 0 auto;
            margin-right: 0.5rem;
        }
    }

    input[type="checkbox"] {
        cursor: pointer;
    }
}
</style>