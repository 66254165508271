<script>
import NumberParser from "@/libraries/NumberParser";

export default {
    components: {},
    props: {
        context: {
            type: Object,
            required: true,
        },
        currency: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            tmp: "",
            focus: false,
        };
    },
    computed: {
        model: function () {
            if (this.context.model != "") {
                return this.$n(this.context.model, "money");
            } else {
                return "";
            }
        },       
    },
    watch: {
        focus: function (f) {
            // When going out of focus -> recalc tmp and put into model (update)
            if (f === false) {
                let parser = new NumberParser(this.$i18n.locale);
                let val = parser.parse(this.tmp);

                if (isNaN(val)) {
                    // Dumb - but to trigger reactive update... need a change.                    
                    this.context.model = " ";
                    this.context.model = "";
                } else {
                    this.context.model = val;
                }
            }
        },
    },
    created() {
        if (this.context.model) {
            this.tmp = this.model;
        }
    },
    methods: {        
        processInput(inp) {
            this.tmp = inp.target.value;
        },
    },
};
</script>

<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
    >
        <template v-if="false">{{ context.model }} / {{ decimal }}</template>
        <div class="input-group vss-currency">
            <input
                type="text"
                :value="model"
                @input="processInput"
                v-bind="context.attributes"
                autocomplete="no"
                @blur="focus = false"
                @focus="focus = true"
            />
            <div class="input-group-append">
                <span class="input-group-text vss-code">
                    <small class="font-weight-bold">
                        <template v-if="currency"> {{ currency }}</template>
                        <template v-else>---</template>
                    </small>
                </span>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.input-group-text.vss-currency {
    border: none !important;
    padding: 0 !important;

    input {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .vss-code {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
</style>