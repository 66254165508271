// Custom in components/Formulate

import PortalVue from 'portal-vue';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// Documentation in the file
import ReqManager from "@/plugins/ReqManager";

import VueFormulateSetup from "@/plugins/formulate";

import VueApexCharts from 'vue-apexcharts'

import i18n from '@/i18n';

// https://sweetalert2.github.io/#usage
const swalOptions = {
    confirmButtonColor: '#80C668',
    cancelButtonColor: '#8898aa',
    denyButtonColor: '#F29A33',
    reverseButtons: true,
    confirmButtonText: i18n.t('swal.button_confirm'),
    cancelButtonText: i18n.t('swal.button_cancel'),
    denyButtonText: i18n.t('swal.button_deny'),
};


export default {
    install(Vue) {
        Vue.use(PortalVue);
        Vue.use(VueSweetalert2, swalOptions);
        Vue.use(ReqManager);
        Vue.use(VueFormulateSetup);
        Vue.use(VueApexCharts)
        // Global config is in the global variable window.Apex
        window.Apex.colors = [
            "#095051",
            "#84A98C",
            "#b0c7c7",
            "#d43d51",
            "#dc6350",
            "#316a62",
            "#eff1c6",
            "#e18458",
            "#548573",
            "#789f85",
            "#e3a268",
            "#9ebb99",
            "#e5be82",
            "#c6d6ae",
            "#e8d8a2",
        ];
        Vue.component('apexchart', VueApexCharts)
    }
};