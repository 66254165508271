import toTime from '@/filters/toTime.js';
import nl2br from "@/filters/nl2br.js"; 
import truncate from "@/filters/truncate.js";
import numanize from "@/filters/numanize.js";

export default {
    install(Vue) {        
        Vue.filter('toTime', toTime);
        Vue.filter('nl2br', nl2br);
        Vue.filter('truncate', truncate);
        Vue.filter('numanize', numanize);
    },
};
