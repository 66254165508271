/* Dashboard */
import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

const dashboardMenu = {
    path: "/dashboard",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.dashboard")
    },
    children: [
        {
            path: "",
            props: true,
            component: () =>
                import(
                    /* webpackChunkName: "dashboard" */ "@/views/Dashboard/FullMomentumDashboard"
                ),
            children: [
                {
                    path: "", // default tab to be loaded!
                    name: "Dashboard",
                    components: {
                        dash_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Dashboard/Tabs/VHoursToday"
                            )
                    },
                    meta: {
                        title: i18n.t("title.dashboard_overview")
                    },
                    props: {
                        dash_tab: true
                    }
                },
                {
                    path: "hours-week",
                    name: "DashboardHoursWeek",
                    components: {
                        dash_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Dashboard/Tabs/VHoursWeek"
                            )
                    },
                    meta: {
                        title: i18n.t("title.dashboard_overview")
                    },
                    props: {
                        dash_tab: true
                    }
                },
                {
                    path: "hours-month",
                    name: "DashboardHoursMonth",
                    components: {
                        dash_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Dashboard/Tabs/VHoursMonth"
                            )
                    },
                    meta: {
                        title: i18n.t("title.dashboard_overview")
                    },
                    props: {
                        dash_tab: true
                    }
                },
                {
                    path: "open-days",
                    name: "DashboardOpenDays",
                    components: {
                        dash_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Dashboard/Tabs/VOpenDays"
                            )
                    },
                    meta: {
                        title: i18n.t("title.dashboard_overview")
                    },
                    props: {
                        dash_tab: true
                    }
                },
                {
                    path: "avg-stats",
                    name: "DashboardAverages",
                    components: {
                        dash_tab: () =>
                            import(
                                /* webpackChunkName: "projects" */ "@/views/Dashboard/Tabs/VAverages"
                            )
                    },
                    meta: {
                        title: i18n.t("title.dashboard_overview")
                    },
                    props: {
                        dash_tab: true
                    }
                },
            ]
        }
    ]
};

export default dashboardMenu;