import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

const helpMenu = {
    path: "/help",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.help")
    },
    children: [
        {
            path: "",
            name: "Help",
            props: route => ({
                helpSlug: route.query.page,
                helpCollection: route.query.collection
            }),
            component: () =>
                import(/* webpackChunkName: "help" */ "@/views/Help/VToc"),
            meta: {
                title: i18n.t("title.help")
            }
        }
    ]
};

export default helpMenu;