import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

/* INVOICING */
const invoicingMenu = {
    path: "/invoicing",
    name: "Invoicing",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.invoicing")
    },
    children: [
        {
            path: "contracts",
            component: () =>
                import(
                    /* webpackChunkName: "invoicing" */ "@/views/Contracts/VInvoicing"
                ),
            meta: {
                title: i18n.t("title.invoicing")
            },
            children: [
                {
                    path: "",
                    name: "ContractsInvoicingReady",
                    components: {
                        invoice: () =>
                            import(
                                /* webpackChunkName: "invoicing" */ "@/views/Contracts/Invoicing/VNewReady"
                            )
                    },
                    meta: {
                        title: i18n.t("title.ready_to_invoice")
                    }
                },
                {
                    path: "missing-invoice-data",
                    name: "ContractsInvoicingMissingInvoiceData",
                    components: {
                        invoice: () =>
                            import(
                                /* webpackChunkName: "invoicing" */ "@/views/Contracts/Invoicing/VNewMissingInvoiceData"
                            )
                    },
                    meta: {
                        title: i18n.t("title.invoicing_missing_invoice_data")
                    }
                },
                {
                    path: "missing-data",
                    name: "ContractsInvoicingMissingData",
                    components: {
                        invoice: () =>
                            import(
                                /* webpackChunkName: "invoicing" */ "@/views/Contracts/Invoicing/VMissingData"
                            )
                    },
                    meta: {
                        title: i18n.t("title.invoicing_missing_data")
                    }
                }
            ]
        },
        {
            path: "contract-balances",
            name: "InvoicingContractBalances",
            component: () =>
                import(
                    /* webpackChunkName: "invoicing" */ "@/views/Contracts/VBalances"
                ),
            meta: {
                title: i18n.t("title.invoicing_contract_balances")
            }
        },

        {
            path: "subscriptions",
            name: "InvoicingSubscriptions",
            component: () =>
                import(
                    /* webpackChunkName: "invoicing" */ "@/views/Subscriptions/VInvoicing"
                ),
            meta: {
                title: i18n.t("title.invoicing_subscriptions")
            }
        },
        {
            path: "charges",
            name: "InvoicingCharges",
            component: () =>
                import(
                    /* webpackChunkName: "invoicing" */ "@/views/Charges/VInvoicing"
                ),
            meta: {
                title: i18n.t("title.invoicing_charges")
            }
        },
        {
            path: "projects",
            name: "InvoicingProjects",
            component: () =>
                import(
                    /* webpackChunkName: "invoicing" */ "@/views/Projects/VInvoicing"
                ),
            meta: {
                title: i18n.t("title.invoicing_projects")
            }
        },
        {
            path: "templates",
            name: "InvoicingTemplates",
            component: () =>
                import(
                    /* webpackChunkName: "invoicing" */ "@/views/Invoicing/VTemplates"
                ),
            meta: {
                title: i18n.t("title.invoicing_templates")
            }
        },
        {
            path: "outbox",
            name: "InvoicingOutbox",
            component: () =>
                import(
                    /* webpackChunkName: "invoicing" */ "@/views/Invoicing/VOutbox"
                ),
            meta: {
                title: i18n.t("title.invoicing_outbox")
            }
        },
        {
            path: "charge-templates",
            name: "ChargeTemplates",
            component: () =>
                import(
                    /* webpackChunkName: "invoicing" */ "@/views/ChargeTemplates/VIndex"
                ),
            meta: {
                title: i18n.t("title.charge_templates")
            }
        }
    ]
};

export default invoicingMenu;
