import i18n from '@/i18n';

export default function (number) {

    let abbreviatedLetter = Math.abs(Number(number)) >= 1.0e+6 ?
        "M" :
        Math.abs(Number(number)) >= 1.0e+3 ?
        "K" :
        "";

    let abbreviatedNumber = Math.abs(Number(number)) >= 1.0e+6 ?
        Math.abs(Number(number)) / 1.0e+6 :
        Math.abs(Number(number)) >= 1.0e+3 ?
        Math.abs(Number(number)) / 1.0e+3 :
        Math.abs(Number(number));

    let formatted = i18n.n(abbreviatedNumber, {
        style: "decimal"
    });


    return `${formatted}${abbreviatedLetter}`;
}