<script>
export default {
    name: 'VTd'
}
</script>

<template>
    <td v-on="$listeners">
        <slot />
    </td>
</template>

