<script>
export default {
    components: {},
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            day: "",
            month: "",
        };
    },
    watch: {
        day: function (v) {
            this.glue();
        },

        month: function (v) {
            this.glue();
        },
        context: function (v) {            
            if (v.model === "") {
                this.day = "";
                this.month = "";
            }
        },
    },
    created() {
        // Incoming format from API: MM-DD
        if (this.context.model !== "") {
            let vals = this.context.model.split("-");
            this.month = vals[0];
            this.day = vals[1];
        } else {
            this.context.model = "";
        }
    },
    methods: {
        glue() {
            if (this.month || this.day) {
                this.context.model =
                    this.pad(this.month, 2) + "-" + this.pad(this.day, 2);
            } else {
                this.context.model = ""; 
            }
        },

        pad(num, size) {
            num = num.toString();
            while (num.length < size) num = "0" + num;
            return num;
        },
    },
};
</script>

<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
    >
        <div class="vss-mday">
            <div class="mday-day">
                <div class="input-group-prepend">
                    <span class="input-group-text vss-mday-name">
                        <small class="font-weight-bold">{{
                            $t("mday.day")
                        }}</small></span
                    >
                </div>
                <div class="input-group">
                    <input
                        type="text"
                        v-model="day"
                        v-bind="context.attributes"
                        maxlength="2"
                        :placeholder="$t('mday.dd_day_placeholder')"
                        autocomplete="no"
                        @blur="context.blurHandler"
                    />
                </div>
            </div>

            <div class="mday-month">
                <div class="input-group-prepend">
                    <span class="input-group-text vss-mday-name">
                        <small class="font-weight-bold">{{
                            $t("mday.month")
                        }}</small></span
                    >
                </div>
                <div class="input-group">
                    <input
                        type="text"
                        v-model="month"
                        :placeholder="$t('mday.mm_month_placeholder')"
                        maxlength="2"
                        v-bind="context.attributes"
                        autocomplete="no"
                        @blur="context.blurHandler"
                        class="mday-month"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.vss-mday {
    border: none !important;
    padding: 0 !important;
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    .mday-day {
        display: flex;
        width: 47%;
    }

    .mday-month {
        display: flex;
        width: 47%;
    }

    .vss-mday-name {
        display: flex;
        width: 80px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
</style>