<script>
export default {
    name: 'VThead'
}
</script>

<template>
    <thead v-on="$listeners">
        <slot />
    </thead>
</template>
