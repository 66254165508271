<script>
import Card from "./Card.vue";

export default {
    name: "VStatsCard",
    components: {
        Card,
    },
    data() {
        return {
            is_active: false,
        };
    },
    props: {
        type: {
            type: String,
            default: "default",
        },
        active: {
            type: Boolean,
            default: false,
        },
        dactive: {
            type: Boolean,
            default: false,
        },
        icon: String,
        title: String,
        value: {            
            default: "",
        },
        iconClasses: [String, Array],
        navigate: Object,
    },
    watch: {
        $route(to, from) {
            this.matchRouteActive();
        },
    },
    created() {
        this.is_active = this.active;
        this.matchRouteActive();
    },
    methods: {
        matchRouteActive() {
            if (this.navigate) {
                if (this.$route.name == this.navigate.name) {
                    this.is_active = true;
                } else {
                    this.is_active = false;
                }
            }
        },
        doNavigation() {
            this.$router.push(this.navigate);
        },
    },
};
</script>

<template>
    <card
        :class="{
            'card-stats card-mg': true,
            'active bg-gradient-mg': is_active || dactive,
            'mark-clickable': is_active == false && navigate,
        }"
        :show-footer-line="true"
        @click="doNavigation"
    >
        <div class="row">
            <div class="col">
                <slot>
                    <h5
                        class="card-title text-uppercase text-muted mb-0"
                        v-if="title"
                    >
                        {{ title }}
                    </h5>
                    <template v-if="$slots.value">
                        <slot name="value"></slot>
                    </template>
                    <template v-else>
                        <span
                            :class="[`h1 font-weight-bold mb-0 text-${type}`]"
                            v-if="value"
                            >{{ value }}</span
                        >
                    </template>
                </slot>
            </div>

            <div class="col-auto" v-if="$slots.icon || icon">
                <slot name="icon">
                    <div
                        class="icon-area"
                        :class="[`text-${type}`, iconClasses]"
                    >
                        <i :class="icon"></i>
                    </div>
                </slot>
            </div>
        </div>

        <p class="mt-3 mb-0 text-sm" v-if="$slots.footer">
            <slot name="footer"> </slot>
        </p>
    </card>
</template>