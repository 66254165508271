<template>
  <bread-crumb list-classes="breadcrumb-links breadcrumb-dark">
    <BreadCrumbItem>
      <li class="breadcrumb-item">
        <router-link to="/">
          <i class="fas fa-home"></i>
        </router-link>
      </li>
    </BreadCrumbItem>
    
    <BreadCrumbItem
      v-for="(route, index) in kutas()"
      :key="route.name"
      :active="index === $route.matched.length - 1"
      style="display:inline-block"      
    >     
      <router-link
        :to="{ name: route.name }"
        v-if="index < $route.matched.length - 1"
      >      
        <template v-if="crumbs.chain[index]">
          {{crumbs.chain[index]}}
        </template>

        <template v-else-if="route.meta.title">
          {{route.meta.title}}
        </template>
        <template v-else>
          {{route.name}}
        </template>
              
      </router-link>
      <span v-else>

        <template v-if="crumbs.chain[index]">
          {{crumbs.chain[index]}}
        </template> 

        <template v-else-if="route.meta.title">
          {{route.meta.title}}
        </template>

        <template v-else>
          {{ route.name }}
        </template>
      </span>
    
    </BreadCrumbItem>
  </bread-crumb>
</template>

<script>
  import BreadCrumb from './Breadcrumb';
  import BreadCrumbItem from './BreadcrumbItem';

  export default {
    name: 'route-breadcrumb',
    props: ["crumbs"],
    components: {
      BreadCrumb,
      BreadCrumbItem
    },
    methods: {
      getBreadName(route) {
        return route.name;
      },

      kutas() {
        console.log(this.$route.matched.slice())
        return this.$route.matched.slice();
      }
      
      

    }
  };
</script>

<style scoped>
  
</style>
