import Http from "@/libraries/Http";

export default {
    apiVersion: 'v1',
    RESTroute: 'users',

    async GET_matchInvoiceSetup(user_id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/match-invoice-setup/${user_id}`, {
            params: params
        });
    },

    async POST_changeLocale(locale) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}/change-locale`, {locale: locale});
    },

    async DELETE_avatar() {
        return Http.delete(`/${this.apiVersion}/${this.RESTroute}/avatar`);
    },

    async POST_avatar(formData) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}/avatar`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    /**
     * params must contain
     * current_password
     * password
     * confirm
     * @param {*} params
     */
    async changePassword(params = {}) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}/change-password`, params);
    },

    /**
     * Returns $me (auth user)
     * @param {} params
     */
    async me(params = {}) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}/me`, {
            params: params
        });
    },

    /**
     * Return ACL role hash.
     * @param {} params
     */
    async roles(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/roles`, {
            params: params
        });
    },

    /**
     * Get a dropdown of ACL roles
     */
    async rolesDropdown() {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/roles`, {
            params: {
                dropdown: true
            }
        });
    },

    async dropdown(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/dropdown`, {
            params: params
        });
    },

    async dropdownAdmins(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/dropdown-admins`, {
            params: params
        });
    },

    async dropdownAll(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/dropdown/all`, {
            params: params
        });
    },

    /**
     * Get all resources
     * GET /resource
     */
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {
            params: params
        });
    },

    /**
     * Get single resource
     * GET /resource/<id>
     */
    async GETS(id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/${id}`, {
            params: params
        });
    },

    /**
     * Create resource
     * POST /resource
     * Payload: {}
     */
    async POST(data) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}`, data);
    },

    /**
     * Put resource
     * PUT /resource/<id>
     * Payload: {}
     */
    async PUT(data) {
        return Http.put(`/${this.apiVersion}/${this.RESTroute}/${data.id}`, data);
    },

    /**
     * Delete resource
     * DELETE /resource/<id>
     */
    async DELETE(id) {
        return Http.delete(`/${this.apiVersion}/${this.RESTroute}/${id}`);
    }

}
