<script>
import VTripleTime from "@/components/Task/Widgets/VTripleTime";
import Cloner from "@/libraries/Cloner";

export default {
    name: "FTripleTime",
    props: {
        context: {
            type: Object,
            required: true,
        },
        enableControls: {
            type: Boolean,
            default: false,
        },
        disableHover: {
            type: Boolean,
            default: false
        },        
    },
    components: { VTripleTime },
    computed: {
        bindAttrs: function () {
            let attrs = Cloner.clone(this.context.attributes);

            if ("class" in attrs) {
                delete attrs["class"];
            }

            return attrs;
        },        
    },
};
</script>

<template>
    <div>
        <v-triple-time
            v-bind="bindAttrs"
            v-model="context.model"
            @blur="context.blurHandler"
            :enable-controls="enableControls"
            :disable-hover="disableHover"
        />
    </div>
</template>

<style>
</style>