import AuthLayout from "@/views/Layout/AuthLayout";
import i18n from "@/i18n.js";

const loginMenu = {
    path: "/login",
    name: "Login",
    component: AuthLayout,
    children: [
        {
            path: "/login",
            name: "LoginPage",
            component: () =>
                import(/* webpackChunkName: "login" */ "@/views/Login/Login"),
            props: true,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/forgot-password",
            name: "ForgotPasswordPage",
            component: () =>
                import(
                    /* webpackChunkName: "login" */ "@/views/Login/ForgotPasswordPage"
                ),
            props: true,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/create-password",
            name: "CreatePasswordPage",
            component: () =>
                import(
                    /* webpackChunkName: "login" */ "@/views/Login/SetupPasswordPage"
                ),
            props: route => ({
                token: route.query.token,
                action: "create"
            }),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/reset-password",
            name: "ResetPasswordPage",
            component: () =>
                import(
                    /* webpackChunkName: "login" */ "@/views/Login/SetupPasswordPage"
                ),
            props: route => ({
                token: route.query.token,
                action: "reset"
            }),
            meta: {
                requiresAuth: false
            }
        }
    ]
};

export default loginMenu;