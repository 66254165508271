<script>
export default {
    components: {},
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            focus: false,
        };
    },
    methods: {
        onBlur(event) {
            this.focus = false;
            this.context.blurHandler(event);
        },
    },
};
</script>

<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
    >
        <div class="input-group vss-search" :class="{ focus: focus }">
            <div class="input-group-prepend" :class="{ focus: focus }">
                <span class="input-group-text">
                    <small class="font-weight-bold"
                        ><i class="far fa-search"></i></small
                ></span>
            </div>
            <input
                type="text"
                v-model="context.model"
                v-bind="context.attributes"
                autocomplete="no"
                @blur="onBlur"
                @focus="focus = true"
            />
            <div class="input-group-append" :class="{ focus: focus }">
                <span class="input-group-text">                                    
                    <i
                        class="far m-click fa-times"
                        v-if="context.model"
                        @click="context.model = ''"
                    ></i
                ></span>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.vss-search {
    border: none !important;
    padding: 0 !important;

    * {
        background: transparent;
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
    }

    &:hover,
    &:focus,
    &.focus {
        * {
            background: white;
            border-radius: 6px;
        }
    }

    ::placeholder {
        color: $gray-600;
    }

    input {
        border-right: none;
        border-left: none;
    }

    .input-group-prepend {

        .input-group-text {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            border-color: $border-color;

                i {
                    color: $gray-600; 
                }
        }
    }

    .input-group-append {
        .input-group-text {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            border-color: $border-color;
        }
    }

    .input-group-prepend.focus {
        .input-group-text {
            border: 1px solid $active;
        }
    }

    .input-group-append.focus {
        .input-group-text {
            border: 1px solid $active;
        }
    }

}
</style>