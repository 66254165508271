import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

const departmentsMenu = {
    path: "/departments",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.departments")
    },
    children: [
        {
            path: "index",
            name: "DepartmentsIndex",
            component: () =>
                import(
                    /* webpackChunkName: "departments" */ "@/views/Departments/VIndex"
                ),
            meta: {
                title: i18n.t("title.departments")
            }
        }
    ]
};

export default departmentsMenu;