import axios from 'axios';

const state = () => ({
    app: null
});



const getters = {
    appCfg(state) {
        return state.app;
    }
};

const actions = {
    async fetchConstants(context) {

        try {
            let http = axios.create({
                baseURL: process.env.VUE_APP_API_URL,
                withCredentials: true,
                maxContentLength: 999999,
                timeout: 30000,
            });

            const r = await http.get(`/v1/settings/constants`);
            context.commit('setCfgConstants', r.data.data);

        } catch (e) {
            console.log("Cannot fetch constants", e);
        }
    },
    /**
     * As above, just returns a promise instead.
     * @param {*} context
     * @returns
     */
    async hydrateConstants(context) {
        let http = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
            withCredentials: true,
            maxContentLength: 999999,
            timeout: 30000,
        });

        return http.get(`/v1/settings/constants`)
            .then(r => {
                context.commit('setCfgConstants', r.data.data);
            })
            .catch(e => {
                console.log("Cannot fetch constants", e);
            });
    }
};

const mutations = {
    setCfgConstants(state, payload) {
        console.log("setCfgConstants", state, payload);
        state.app = payload;
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
