import Vue from "vue";
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// vuex modules
import auth from "@/store/modules/auth";
import ui from "@/store/modules/ui";
import backend from "@/store/modules/backend";


Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        auth,        
        ui,
        backend
    },

    plugins: [createPersistedState({
            storage: window.sessionStorage,
            overwrite: true
        }),
        //wssPlugin 
    ],
});


export default store;