<script>
export default {
    name: "VEntityErrors",
    props: {
        errors: {
            description: "Errors from API",
        },
    },
};
</script>

<template>
    <ul class="v-entity-errors">
        <li v-for="(errorlist, field) in errors" :key="field">
            {{$t('entityErrors.field_name')}}: <span class="v-entity-errors__field text-danger">{{field}}</span>
            <ul>
                <li v-for="(error, index) in errorlist" :key="index" class="text-danger">
                    {{error}}
                </li>
            </ul>
        </li>
    </ul>
</template>

<style lang="scss">
    .v-entity-errors {
        &__field {
            font-style: italic;
        }
    }
</style>