<template>
    <base-nav
        container-classes="container-fluid"
        class="navbar-top border-bottom navbar-expand"
        :class="
            $route.meta.navbarClasses
                ? $route.meta.navbarClasses
                : 'bg-white navbar-dark'
        "
        type=""
    >
        <!-- Search form -->
        <div class="topbar-container">
            <div class="topbar-container__goto">
                <v-customer-goto />
            </div>
            <div class="topbar-container__buttons">
                <v-shortcuts />
            </div>
        </div>
        <template v-if="false">
            <form
                class="navbar-search form-inline mr-sm-3"
                :class="
                    $route.meta.searchClasses
                        ? $route.meta.searchClasses
                        : 'navbar-search-light'
                "
                id="navbar-search-main"
            >
                <div class="form-group mb-0">
                    <div
                        class="input-group input-group-alternative input-group-merge"
                    >
                        <div class="input-group-prepend">
                            <span class="input-group-text"
                                ><i class="fas fa-search"></i
                            ></span>
                        </div>
                        <input
                            class="form-control"
                            placeholder=""
                            type="text"
                        />
                    </div>
                </div>
                <button
                    type="button"
                    class="close"
                    data-action="search-close"
                    data-target="#navbar-search-main"
                    aria-label="Close"
                >
                    <span aria-hidden="true">×</span>
                </button>
            </form>
        </template>
        <!-- Navbar links -->
        <ul class="navbar-nav align-items-center ml-md-auto">
            <li class="nav-item d-xl-none ml-4">
                <!-- Sidenav toggler -->
                <div
                    class="pr-3 sidenav-toggler sidenav-toggler-dark duplicated-toggler"
                    @click="toggleSidebar"
                >
                    <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                    </div>
                </div>
            </li>
            <li class="nav-item d-sm-none" v-if="false">
                <a
                    class="nav-link"
                    href="#"
                    data-action="search-show"
                    data-target="#navbar-search-main"
                >
                    <i class="ni ni-zoom-split-in"></i>
                </a>
            </li>
        </ul>
        <ul class="navbar-nav align-items-center ml-auto ml-md-0" v-if="false">
            <!-- removed li item from here - place for the timer? -->
        </ul>
    </base-nav>
</template>
<script>
import { BaseNav } from "@/components";
import VShortcuts from "@/components/Momentum/VShortcuts";

export default {

    components: {
        BaseNav,
        VShortcuts
    },
    computed: {
        routeName() {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        },
        isRTL() {
            return this.$rtl.isRTL;
        },
    },
    data() {
        return {
            activeNotifications: true,
            showMenu: true,
            searchModalVisible: false,
            searchQuery: "",
        };
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown() {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown() {
            this.activeNotifications = false;
        },
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar() {
            this.$sidebar.displaySidebar(false);
        },
    },
};
</script>
<style scoped>
.top-navbar {
    top: 0px;
}

.duplicated-toggler .sidenav-toggler-line {
    background-color: black !important;
}
</style>


