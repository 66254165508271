import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

/* CONTRACTS */
const contractsMenu = {
    path: "/contracts",
    name: "Contracts",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.contracts")
    },
    children: [
        
        {
            path: "netprices",
            name: "ContractsNetPriceIndices",
            component: () =>
                import(
                    /* webpackChunkName: "contracts" */ "@/views/NetPriceIndices/VIndex"
                ),
            meta: {
                title: i18n.t("title.net_price_indices")
            }
        },
        {
            path: "currencies",
            name: "ContractsCurrencies",
            component: () =>
                import(
                    /* webpackChunkName: "contracts" */ "@/views/Currencies/VIndex"
                ),
            meta: {
                title: i18n.t("title.currencies")
            }
        },
        {
            path: "asset-groups",
            name: "ContractsAssetGroups",
            component: () =>
                import(
                    /* webpackChunkName: "contracts" */ "@/views/AssetGroups/VIndex"
                ),
            meta: {
                title: i18n.t("title.asset_categories")
            }
        },
        {
            path: "products",
            name: "ContractsProducts",
            component: () =>
                import(
                    /* webpackChunkName: "contracts" */ "@/views/Contracts/VProducts"
                ),
            meta: {
                title: i18n.t("title.contract_products")
            }
        },
        {
            path: "reports",
            name: "ContractsReports",
            component: () =>
                import(
                    /* webpackChunkName: "contracts" */ "@/views/Contracts/VReports"
                ),
            meta: {
                title: i18n.t("title.reports")
            }
        },
        {
            path: "settings",
            name: "ContractsSettings",
            component: () =>
                import(
                    /* webpackChunkName: "contracts" */ "@/views/Contracts/VSettings"
                ),
            meta: {
                title: i18n.t("title.settings")
            }
        },
        {
            path: "index",
            name: "ContractsIndex",
            component: () =>
                import(
                    /* webpackChunkName: "contracts" */ "@/views/Contracts/VIndex"
                ),
            meta: {
                title: i18n.t("title.contract_index")
            }
        },
        {
            path: "terminations",
            name: "ContractsTerminations",
            component: () =>
                import(
                    /* webpackChunkName: "contracts" */ "@/views/Contracts/VTerminations"
                ),
            meta: {
                title: i18n.t("title.contract_terminations")
            }
        }
    ]
};

export default contractsMenu;