import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

/* Travels */
const travelsMenu = {
    path: "/travels",
    name: "Travels",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.travels")
    },
    children: [
        {
            path: "index",
            name: "TravelsAllTravels",
            component: () =>
                import(
                    /* webpackChunkName: "travels" */ "@/views/Travels/VIndex"
                ),
            meta: {
                title: i18n.t("title.all_travels")
            }
        },
        {
            path: "salary",
            name: "TravelsSalary",
            component: () =>
                import(
                    /* webpackChunkName: "travels" */ "@/views/Travels/VSalary"
                ),
            meta: {
                title: i18n.t("title.travel_salary")
            }
        },
        {
            path: "salary-reports",
            name: "TravelsSalary",
            component: () =>
                import(
                    /* webpackChunkName: "travels" */ "@/views/Travels/VSalaryReports"
                ),
            meta: {
                title: i18n.t("title.travel_salary_reports")
            }
        },
        {
            path: "my-travels",
            name: "TravelsMyTravels",
            component: () =>
                import(
                    /* webpackChunkName: "travels" */ "@/views/Travels/VMyTravels"
                ),
            meta: {
                title: i18n.t("title.my_travels")
            }
        }
    ]
};

export default travelsMenu;