<script>
export default {    
    name: "NewVersionModal",
    props: {
        show: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        reloadPage() {
            window.location.reload();
        }
    }
};
</script>

<template>
    <modal :show="show" @close="$emit('close')" class="new-version-modal">
        <template slot="header">
            {{ $t("system.new_version_available_title") }}
        </template>

        <template slot="default">
            <div class="text-center">
                {{ $t("system.new_version_available_text") }}
            </div>

            <div class="text-center mt-5 pb-3">
                <base-button size="lg" type="primary" @click="reloadPage">{{
                    $t("system.reload_page")
                }}</base-button>
            </div>
        </template>
    </modal>
</template>

<style>
</style>