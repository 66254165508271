import Http from "@/libraries/Http";

export default {
    apiVersion: "v1",
    RESTroute: "tasks",

    async PUT_updatePrices(data, params = {}) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/update-prices`,
            data
        );
    },

    async GET_manager(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/manager/${project_id}`,
            {
                params: params
            }
        );
    },

    async GET_myReport(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/my-report`, {
            params: params
        });
    },

    async GET_averageStats(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/average-stats`, {
            params: params
        });
    },

    async GET_personalMultiviewReport(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/personal-multiview-report`,
            {
                params: params
            }
        );
    },

    async GET_openDaysReport(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/open-days-report`,
            {
                params: params
            }
        );
    },

    async GET_absence(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/absence`, {
            params: params
        });
    },

    async GET_absenceDetails(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/absence-details`,
            {
                params: params
            }
        );
    },

    async GET_activityStatistics(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/activity-statistics`,
            {
                params: params
            }
        );
    },

    async GET_absenceIndex(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/absence-index`, {
            params: params
        });
    },

    async PUT_systemTask(data) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/system-task/${data.id}`,
            data
        );
    },
    /**
     * Create a system task
     */
    async POST_systemTask(data) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTroute}/system-task`,
            data
        );
    },

    /**
     * Get before-close-day status. Uses session for user ident.
     * @param {*} date
     * @param {*} params
     * @returns
     */
    async GET_precheckGoalForClosing(date, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/precheck-goal-for-closing/${date}`,
            {
                params: params
            }
        );
    },

    /**
     * Close a given day. Uses session for user ident.
     * @param {} date
     * @param {*} params
     * @returns
     */
    async PUT_closeDay(date, params = {}) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/close-day/${date}`,
            {},
            {
                params: params
            }
        );
    },

    async PUT_closeMultipleDays(data, params = {}) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/close-multiple-days`,
            data,
            {
                params: params
            }
        );
    },

    /**
     * Return status of days for a logged in user.
     * Requires startDate and endDate params
     * @param {} params
     * @returns
     */
    async GET_daysStatus(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/days-status`, {
            params: params
        });
    },

    async GET_periodStats(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/period-stats`, {
            params: params
        });
    },

    async GET_dashboardStats(params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/dashboard-stats`,
            {
                params: params
            }
        );
    },

    async GET_dayStats(date, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/day-stats/${date}`,
            {
                params: params
            }
        );
    },

    /**
     * Get all resources
     * GET /resource
     */
    async GET_projectStats(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/project-stats`, {
            params: params
        });
    },

    /**
     * Get all resources
     * GET /resource
     */
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {
            params: params
        });
    },

    /**
     * Get single resource
     * GET /resource/<id>
     */
    async GETS(id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/${id}`, {
            params: params
        });
    },

    /**
     * Create resource
     * POST /resource
     * Payload: {}
     */
    async POST(data) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}`, data);
    },

    /**
     * Put resource
     * PUT /resource/<id>
     * Payload: {}
     */
    async PUT(data, params = {}) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/${data.id}`,
            data,
            {
                params: params
            }
        );
    },

    /**
     * Delete resource
     * DELETE /resource/<id>
     */
    async DELETE(id) {
        return Http.delete(`/${this.apiVersion}/${this.RESTroute}/${id}`);
    }
};
