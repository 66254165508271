<script>
import NumberParser from "@/libraries/NumberParser";

export default {
    components: {},
    props: {
        context: {
            type: Object,
            required: true,
        },
        append: {
            type: String,
        },
        prepend: {
            type: String,
        },
        precision: {
            type: Number,
            default: -1,
        },
        align: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            tmp: "",
            focus: false,
        };
    },
    computed: {
        model: function () {
            if (this.context.model != "") {
                if (this.precision >= 0) {
                    return this.$n(this.context.model, {
                        style: "decimal",
                        minimumFractionDigits: this.precision,
                        maximumFractionDigits: this.precision,
                    });
                } else {
                    return this.$n(this.context.model);
                }
            } else {
                return "";
            }
        },
    },
    watch: {
        focus: function (f) {
            // When going out of focus -> recalc tmp and put into model (update)
            if (f === false) {
                let parser = new NumberParser(this.$i18n.locale);
                let val = parser.parse(this.tmp);

                if (isNaN(val)) {
                    // Dumb - but to trigger reactive update... need a change.
                    this.context.model = " ";
                    this.context.model = "";
                } else {
                    if (this.precision >= 0) {
                        this.context.model = parseFloat(val).toFixed(
                            this.precision
                        );
                    } else {
                        this.context.model = val;
                    }
                }
            }
        },
    },
    created() {
        if (this.context.model) {
            this.tmp = this.model;
        }
    },
    methods: {
        processInput(inp) {
            // Write to a temp var -> update field on blur
            this.tmp = inp.target.value;
        },
    },
};
</script>

<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
    >
        <div class="input-group vss-currency">
            <div class="input-group-prepend" v-if="prepend">
                <span class="input-group-text vss-code">
                    <small class="font-weight-bold">{{ prepend }}</small></span
                >
            </div>
            <input
                type="text"                
                :value="model"
                @input="processInput"
                v-bind="context.attributes"
                autocomplete="no"
                @focus="focus = true"
                @blur="focus = false"
                :style="align !== '' ? `text-align: ${align}` : ''"
            />
            <div class="input-group-append" v-if="append">
                <span class="input-group-text vss-code">
                    <small class="font-weight-bold">{{ append }}</small></span
                >
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.input-group-text.vss-currency {
    border: none !important;
    padding: 0 !important;

    input {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;                        
        
    }

    .vss-code {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
</style>