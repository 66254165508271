import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import store from "@/store";
import i18n from "@/i18n.js";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes,
    linkActiveClass: 'active',
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {
                selector: to.hash
            };
        }
        return {
            x: 0,
            y: 0
        };
    }
});

/**
 * Authentication guard
 */
router.beforeEach(async (to, from, next) => {
    let requiresAuth = true;

    if ('meta' in to && 'requiresAuth' in to.meta) {
        requiresAuth = to.meta.requiresAuth;
    }

    // OAuth2 handler
    if ('oauth2' in to.meta) {        
        await store.dispatch("auth/oauth2HydrateStore");
        // Set locale
        i18n.locale = store.getters['auth/getUserLocale'];
        // Hydrate constants
        await store.dispatch("backend/hydrateConstants");

        const isImpersonation = to.query.impersonate;

        if (parseInt(isImpersonation) === 1) {
            store.commit("auth/setImpersonation", true);
        } else {
            store.commit("auth/setImpersonation", false);
        }

        next({name: 'StartDashboard'});
    }

    if (requiresAuth) {
        if (!store.getters['auth/isAuthenticated']) {
            next({
                name: 'LoginPage'
            });
        } else {
            next();
        }
    } else {
        next();
    }

    /*

    alternative:
    if (to.matched.some(record => record.meta.requiresAuth)) {

    }

    meta -> child routes will match
    if (to.meta.requiresAuth) {
      // need to login
      if (!store.user) {
        next({
          name: "login"
        });
      } else {
        next();
      }
    } else {
      next(); // just continue
    }

    */
});

export default router;
