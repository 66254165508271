<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
    name: "modal",
    components: {
        SlideYUpTransition,
    },
    props: {
        show: Boolean,
        showClose: {
            type: Boolean,
            default: true,
        },
        centered: {
            type: Boolean, 
            default: true, 
            description: "Center middle V & H"
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "",
            validator(value) {
                let acceptedValues = ["", "notice", "mini"];
                return acceptedValues.indexOf(value) !== -1;
            },
            description: 'Modal type (notice|mini|"") ',
        },
        modalClasses: {
            type: [Object, String],
            description: "Modal dialog css classes",
        },
        size: {
            type: String,
            description: "Modal size",
            validator(value) {
                let acceptedValues = ["", "sm", "lg", "xl", "xxl", "full"]; 
                return acceptedValues.indexOf(value) !== -1;
            },
        },
        modalContentClasses: {
            type: [Object, String],
            description: "Modal dialog content css classes",
        },
        gradient: {
            type: String,
            description: "Modal gradient type (danger, primary etc)",
        },
        headerClasses: {
            type: [Object, String],
            description: "Modal Header css classes",
        },
        bodyClasses: {
            type: [Object, String],
            description: "Modal Body css classes",
        },
        footerClasses: {
            type: [Object, String],
            description: "Modal Footer css classes",
        },
        animationDuration: {
            type: Number,
            default: 500,
            description: "Modal transition duration",
        },
        loading: {
            type: Boolean,
            default: false,
            description: "Loading indicator for the whole modal content",
        },
    },
    methods: {
        closeModal() {
            this.$emit("update:show", false);
            this.$emit("close");
        },
    },
    watch: {
        show(val) {
            /** REMOVED 2021-04-23 not working. Ported from Effihub a solution */
            let documentClasses = document.body.classList;
            if (val) {
                console.log("Modal add");
                //documentClasses.add("modal-open");
            } else {
                console.log("Modal remove");
                //documentClasses.remove("modal-open");
            }
        },
    },
    created() {
        console.log("MODAL-ON", this.$parent.name);
        console.log("SIZE: " + this.size);
        document.getElementsByTagName("body")[0].classList.add("modal-open");
    },
    beforeDestroy() {
        console.log("MODAL-OFF");
        document.getElementsByTagName("body")[0].classList.remove("modal-open");
    }


};
</script>

<template>
    <SlideYUpTransition :duration="animationDuration">
        <div
            class="modal fade"            
            @mousedown.self="!persistent ? closeModal : () => {}"
            :class="[
                { 'show d-block': show },
                { 'd-none': !show },
                { 'modal-mini': type === 'mini' },
            ]"
            v-show="show"
            tabindex="-1"
            role="dialog"
            :aria-hidden="!show"
        >
            <div
                class="modal-dialog"
                :class="[
                    {
                        'modal-notice': type === 'notice',
                        [`modal-${size}`]: size,
                        'modal-dialog-centered': centered
                    },
                    modalClasses,
                ]"
            >
                <template v-if="loading">
                    <div
                        class="modal-content"
                        :class="[
                            gradient ? `bg-gradient-${gradient}` : '',
                            modalContentClasses,
                        ]"
                    >
                        <div class="modal-body" :class="bodyClasses">
                            <h3 class="text-center">{{$t('please_wait_loading')}}</h3>
                            <v-loader :loading="loading" />
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div
                        class="modal-content"
                        :class="[
                            gradient ? `bg-gradient-${gradient}` : '',
                            modalContentClasses,
                        ]"
                    >
                        <div
                            class="modal-header"
                            :class="[headerClasses]"
                            v-if="$slots.header"
                        >
                            <slot name="header"></slot>
                            <slot name="close-button">
                                <button
                                    type="button"
                                    class="close"
                                    v-if="showClose"
                                    @click="closeModal"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span :aria-hidden="!show"
                                        ><i class="fas fa-times"></i
                                    ></span>
                                </button>
                            </slot>
                        </div>

                        <div class="modal-body" :class="bodyClasses">
                            <slot></slot>
                        </div>

                        <div
                            class="modal-footer"
                            :class="footerClasses"
                            v-if="$slots.footer"
                        >
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </SlideYUpTransition>
</template>


<style>
.modal.show {
    background-color: rgba(0, 0, 0, 0.3);
}
</style>

<style lang="scss">
.modal {
    .modal-dialog {
        .modal-header {
            font-size: 17px;
            font-weight: bold;
            color: #095051;
            border-bottom: 1px solid #e9ecef;

            .close {
                padding-bottom: 0px;
            }
        }
    }
}
</style>
