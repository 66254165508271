import Http from "@/libraries/Http";

export default {
    apiVersion: "v1",
    RESTroute: "project-milestones",

    async PUT_flipStatus(project_milestone_id) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/flip-status/${project_milestone_id}`
        );
    },

    async POST_addToProject(project_id, data, params = {}) {
        return Http.post(
            `/${this.apiVersion}/${this.RESTroute}/add-to-project/${project_id}`,
            data,
            { params: params }
        );
    },

    async GET_getForAdding(project_id, params = {}) {
        return Http.get(
            `/${this.apiVersion}/${this.RESTroute}/get-for-adding/${project_id}`,
            { params: params }
        );
    },

    async GET_dropdown(project_id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/dropdown/${project_id}`, {
            params: params
        });
    },

    async GET_invoicingDropdown(project_id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/invoicing-dropdown/${project_id}`, {
            params: params
        });
    },
    /**
     * Get all resources
     * GET /resource
     */
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {
            params: params
        });
    },

    /**
     * Get single resource
     * GET /resource/<id>
     */
    async GETS(id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/${id}`, {
            params: params
        });
    },

    /**
     * Create resource
     * POST /resource
     * Payload: {}
     */
    async POST(data) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}`, data);
    },

    /**
     * Put resource
     * PUT /resource/<id>
     * Payload: {}
     */
    async PUT(data) {
        return Http.put(
            `/${this.apiVersion}/${this.RESTroute}/${data.id}`,
            data
        );
    },

    /**
     * Delete resource
     * DELETE /resource/<id>
     */
    async DELETE(id) {
        return Http.delete(`/${this.apiVersion}/${this.RESTroute}/${id}`);
    }
};
