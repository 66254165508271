<script>
export default {
    
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    methods: {
        doNothing() {

            return false;
        }
    }
};
</script>

<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
        @keydown.enter.prevent="doNothing"
    >
        <textarea-autosize            
            v-model="context.model"
            v-bind="context.attributes"
            autocomplete="no"            
            rows="1"
            @blur="context.blurHandler"
        />
    </div>
</template>

<style lang="scss">


.input-group-text.vss-multiline {
    border: none !important;
    padding: 0 !important;

    input {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }    
}
</style>