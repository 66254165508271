import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

const oauth2Menu = {
    path: "/oauth2-signin",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.please_wait"),
        oauth2: true
    }
};

export default oauth2Menu;