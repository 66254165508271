import Http from "@/libraries/Http"; 

export default {
    apiVersion: 'v1', 
    RESTroute: 'customers',

    async GET_internalTimeStatistics(customer_id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/internal-time-statistics/${customer_id}`, {params: params}); 
    },

    /**
     * Return a single customer discount
     * @param {*} customer_id 
     * @returns 
     */
    async GET_discount(customer_id) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/discounts/${customer_id}`); 
    }, 

    /**
     * Return all customer discounts
     * @returns 
     */
    async GET_discounts() {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/discounts`); 
    }, 

    /**
     * Checks whether a given customer is internal or not. Returns id, name & internal_customer
     * @param {} customer_id 
     * @returns 
     */
    async GET_isInternalCustomer(customer_id) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/is-internal-customer/${customer_id}`); 
    }, 

    async dropdown(params = {}) {        
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/dropdown`, {params: params});
    },

    /** 
     * Get all resources 
     * GET /resource
     */
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {params: params}); 
    }, 
     
    /** 
     * Get single resource
     * GET /resource/<id>
     */
    async GETS(id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/${id}`, {params: params}); 
    }, 

    /** 
     * Create resource
     * POST /resource 
     * Payload: {}
     */
    async POST(data) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}`, data);
    },

    /** 
     * Put resource
     * PUT /resource/<id>
     * Payload: {}
     */
    async PUT(data) {        
        return Http.put(`/${this.apiVersion}/${this.RESTroute}/${data.id}`, data);
    }, 

    /** 
     * Delete resource
     * DELETE /resource/<id>
     */
    async DELETE(id) {
        return Http.delete(`/${this.apiVersion}/${this.RESTroute}/${id}`);
    },

    /** 
     * Special endpoint for GoTo customer
     */
    async goToCustomer(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/goto-customer`, {params: params});
    }, 

    /** 
     * Get invoices
     */
    async getInvoices(customer_id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/get-invoices/${customer_id}`, {params: params});
    }

    
}