var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlideYUpTransition',{attrs:{"duration":_vm.animationDuration}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"modal fade custom-modal",class:[
            { 'show d-block': _vm.show },
            { 'd-none': !_vm.show },
            { 'modal-mini': _vm.type === 'mini' } ],attrs:{"tabindex":"-1","role":"dialog","aria-hidden":!_vm.show},on:{"mousedown":function($event){if($event.target !== $event.currentTarget){ return null; }!_vm.persistent ? _vm.closeModal : function () {}}}},[_c('div',{staticClass:"modal-dialog custom-modal-dialog",class:[
                ( _obj = {
                    'modal-notice': _vm.type === 'notice'
                }, _obj[("modal-" + _vm.size)] = _vm.size, _obj['modal-dialog-centered'] =  _vm.centered, _obj ),
                _vm.modalClasses ]},[(_vm.loading)?[_c('div',{staticClass:"modal-content",class:[
                        _vm.gradient ? ("bg-gradient-" + _vm.gradient) : '',
                        _vm.modalContentClasses ]},[_c('div',{staticClass:"modal-body",class:_vm.bodyClasses},[_c('h3',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("please_wait_loading"))+" ")]),_c('v-loader',{attrs:{"loading":_vm.loading}})],1)])]:[_c('div',{staticClass:"modal-content custom-modal-content",class:[
                        _vm.gradient ? ("bg-gradient-" + _vm.gradient) : '',
                        _vm.modalContentClasses ]},[(_vm.$slots.header)?_c('div',{staticClass:"custom-modal-header",class:[_vm.headerClasses]},[_c('div',{staticClass:"custom-modal-header--slot"},[_vm._t("header")],2),_c('div',{staticClass:"custom-modal-header--close"},[_vm._t("close-button",function(){return [(_vm.showClose)?_c('button',{attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","disabled":_vm.saving},on:{"click":_vm.closeModal}},[_c('span',{attrs:{"aria-hidden":!_vm.show}},[_c('i',{staticClass:"fas fa-2x fa-fw",class:{
                                                'fa-times': !_vm.saving,
                                                'fa-spinner fa-pulse': _vm.saving,
                                            }})])]):_vm._e()]})],2)]):_vm._e(),_c('div',{staticClass:"custom-modal-body",class:_vm.bodyClasses},[_vm._t("default")],2)])]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }