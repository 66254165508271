/**
 *
 *
 * Variables:
 * - apiPaginator: these are the GET variables for pagination - as in CakePHP
 * - apiSearch: Object containing all v-model fields for $resetSearch
 * - apiFilterView: object {filter_Id: filter_name} for filter views
 *
 *
 * apiPaginator: {
 *        sort: null,
 *        direction: null,
 *        page: null,
 *        limit: null,
 *    },
 *    apiSearchFilter: {},
 *    apiFilterView: null,
 *
 * Inititalization:
 *  ```
    this.apiSearchFilter = this.initSearch(); -> init all vmodels
    this.apiFilterView = "active"; -> set the initial filterView
    this.$apiEnableWatcher(); -> enable watchers
    this.fetchData(); -> fetch data (initial)
    ```
 *
 *
 *
 *
 */
import { isEmpty, forIn, debounce } from "lodash";

import dayjs from "dayjs";

const defaultOptions = {
    debounceTime: 500,
    fetchFunction: "fetchData"
};

export default {
    install(Vue, options) {
        let config = {
            ...defaultOptions,
            ...options
        };

        /**
         *  Build request parameters
         *
         * @param {} opts
         */
        Vue.prototype.$buildParams = function(opts) {
            let params = {};

            if (this.apiPaginator.sort) {
                params["sort"] = this.apiPaginator.sort;
            }

            if (this.apiPaginator.direction) {
                params["direction"] = this.apiPaginator.direction;
            }

            if (this.apiPaginator.page) {
                params["page"] = this.apiPaginator.page;
            }

            if (this.apiPaginator.limit) {
                params["limit"] = this.apiPaginator.limit;
            }

            if (this.apiFilterView && this.apiFilterView != "null") {
                params["fview"] = this.apiFilterView;
            }

            if (!isEmpty(this.apiSearchFilter)) {
                forIn(this.apiSearchFilter, function(value, key) {
                    //console.log("apiSearchFilter", key, value);
                    // if (value && value != 'null') {
                    //     params[key] = value;
                    // }
                    if (value && value != "null" && value != "__NONE__") {
                        if (
                            typeof value === "object" &&
                            "endDate" in value &&
                            "startDate" in value
                        ) {
                            if (value.startDate && value.endDate) {
                                params[key] = {
                                    startDate: dayjs(value.startDate).format(),
                                    endDate: dayjs(value.endDate).format()
                                };
                            }
                        } else {
                            params[key] = value;
                        }
                    }
                });
            }

            return params;
        };

        /**
         *
         * @param {} page
         */
        Vue.prototype.$resetSearch = function() {
            this.apiPaginator.page = null;
            this.apiPaginator.sort = null;
            this.apiPaginator.direction = null;
        };

        /**
         * Pagination
         */
        Vue.prototype.$handlePagination = function(page) {
            this.apiPaginator.page = page;
            this[config.fetchFunction]();
        };
        /**
         * Handle sorting
         */
        Vue.prototype.$handleSorting = function(params) {
            this.apiPaginator.sort = params.sort;
            this.apiPaginator.direction = params.direction;
            this[config.fetchFunction]();
        };

        /**
         * Handle filter view
         */
        Vue.prototype.$handleFilterView = function(view) {
            if (view == "null") {
                this.apiFilterView = null;
            } else {
                this.apiFilterView = view;
            }
        };

        /**
         * Enable watchers
         */
        Vue.prototype.$apiEnableWatcher = function() {
            this.$watch(
                "apiSearchFilter",
                debounce(function() {
                    console.log("Debounce");
                    this.apiPaginator.page = null;
                    this[config.fetchFunction]();
                }, config.debounceTime),

                {
                    deep: true
                }
            );

            this.$watch("apiFilterView", function() {
                this.apiPaginator.page = null;
                this[config.fetchFunction]();
            });
        };

        Vue.mixin({
            data() {
                return {
                    apiPaginator: {
                        sort: null,
                        direction: null,
                        page: null,
                        limit: null
                    },
                    apiSearchFilter: {},
                    apiFilterView: null
                };
            }
        });
    }
};
