<script>
import VueSelect from "vue-select";
import CustomerService from "@/services/CustomerService";

export default {
    name: "VCustomerGoto",
    components: {
        VueSelect,
    },
    data() {
        return {
            loading: false,
            customers: [],
            customer_id: null,
        };
    },
    methods: {
        async fetchCustomers() {
            try {
                this.loading = true;
                const r = await CustomerService.goToCustomer();
                this.customers = r.data.data;
            } catch (e) {}

            this.loading = false;
        },

        selectCustomer(c) {
            if (c.internal_customer == 1) {
                this.navigate("CustomersTabInternalTime", c.id);
            } else {
                this.navigate("CustomersTabCases", c.id);
                // this.navigate("CustomersTabContracts", c.id);
                // this.navigate("CustomersManage", c.id);
            }

            // Reset v-model or else select won't react again on customer change
            this.customer_id = null;
        },

        navigate(where, id) {
            console.log("PUSH", where, id);
            try {
                this.$router.push({
                    name: where,
                    params: {
                        customer_id: id,
                    },
                });
            } catch (e) {
                console.log("Duplicate navigation");
            }
        },
    },
};
</script>

<template>
    <vue-select
        class="v-goto-customer"
        :clearable="false"
        :options="customers"
        :placeholder="$t('goto.customer-placeholder')"
        label="name"
        v-model="customer_id"
        :loading="loading"
        @input="selectCustomer"
        @open="fetchCustomers"
    >
        <template #selected-option>
            <div style="display: flex; align-items: baseline">
                <span class="text-muted">{{
                    $t("goto.customer-placeholder")
                }}</span>
            </div>
        </template>
        <template #option="{ name, count_contracts, count_cases }">
            <div class="goto-container">
                <div class="goto-container__name">{{ name }}</div>

                <div class="goto-container__buttons">
                    <template v-if="count_cases">
                        <i class="fal fa-clipboard-list-check"></i>
                    </template>
                    <template v-else>
                        <i></i>
                    </template>

                    <template v-if="count_contracts">
                        <i class="fal fa-file-signature"></i
                    ></template>
                    <template v-else>
                        <i></i>
                    </template>
                </div>
            </div>
        </template>

        <div slot="no-options">{{ $t("goto.empty-set") }}</div>
    </vue-select>
</template>

<style lang="scss">
.v-goto-customer {
    margin-top: 0;

    .vs__dropdown-menu {
        width: auto;
        border-top: 1px solid $gray-400;
        border-radius: 6px;
        margin-top: 0px;

        @media (min-width: 1280px) {
            width: 500px;
        }
    }

    &.vs--open .vs__dropdown-toggle {
        border-bottom-color: $border-color !important;
        border-bottom-left-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
    }

    .goto-container {
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        &__name {
            justify-content: left;
            display: flex;
            flex-direction: column;
            font-size: 13px;
            width: 85%;
            overflow: hidden;
            margin-right: 5%;
        }

        &__buttons {
            display: flex;
            justify-content: left;
            width: 10%;

            i {
                display: flex;
                width: 50%;
            }

            i:first-of-type {
                margin-right: 15px;
            }
        }

        @media (max-width: 580px) {
            &__name {
                width: 75%;
                margin-right: 2%;
            }

            &__buttons {
                display: flex;
                justify-content: left;
                width: 5%;

                i {
                    display: flex;
                    width: 50%;
                }

                i:first-of-type {
                    margin-right: 5px;
                }
            }
        }
    }
}
</style>