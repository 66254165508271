<script>
import VTaskForm from "@/components/Forms/VTaskForm";
import VTravelForm from "@/components/Forms/VTravelForm";
import VSystemActivityForm from "@/components/Forms/VSystemActivityForm";
import { EventBus } from "@/libraries/EventBus";
import { mapGetters } from "vuex";

export default {
    name: "VShortcuts",
    components: {
        VTaskForm,
        VTravelForm,
        VSystemActivityForm
    },
    data() {
        return {
            newVersion: false,
            showTaskModal: false,
            showHolidayModal: false,
            showExpensesModal: false,
            showTravelModal: false,
            showSystemActivityModal: false
        };
    },
    computed: {
        ...mapGetters("auth", ["getIsImpersonated"])
    },
    watch: {
        /**
         * Watch the route for changes and if possible
         * set "REgister time"
         */
        $route(to, from) {
            console.log("VShortcuts: ROUTE CHANGE", to.path, to.params);
        }
    },
    created() {
        EventBus.$on("new-system-version", this.newVersionAvailable);
        EventBus.$on("register-absence", this.openSystemActivityModal);
    },
    beforeDestroy() {
        EventBus.$off("new-system-version");
        EventBus.$off("register-absence");
    },
    methods: {
        newVersionAvailable() {
            this.newVersion = true;
        },
        reloadPage() {
            window.location.reload();
        },
        openTaskModal() {
            this.showTaskModal = true;
        },
        closeTaskModal() {
            this.showTaskModal = false;
        },
        taskSaved(task) {
            if (task.project_id) {
                this.$router.push({
                    name: "ProjectViewTasks",
                    params: { project_id: task.project_id }
                });
            }

            this.showTaskModal = false;
        },
        openTravelModal() {
            this.showTravelModal = true;
        },
        closeTravelModal() {
            this.showTravelModal = false;
        },
        travelSaved(travel) {
            this.showTravelModal = false;
        },
        /**
         * System activity modal: open
         */
        openSystemActivityModal() {
            this.showSystemActivityModal = true;
        },
        closeSystemActivityModal() {
            this.showSystemActivityModal = false;
        },
        saveSystemActivity(act) {
            this.showSystemActivityModal = false;
            EventBus.$emit("refresh-dashboard");
            EventBus.$emit("task-created");
        }
    }
};
</script>

<template>
    <div class="v-shortcuts">
        <div class="v-shortcuts__content">
            <div v-if="false" class="v-shortcuts__left">
                <base-button size="sm" :outline="true" type="primary"
                    ><i class="far fa-bell"></i
                ></base-button>
            </div>
            <div class="v-shortcuts__right">
                <base-button
                    v-if="newVersion"
                    size="sm"
                    :outline="false"
                    type="danger"
                    @click="reloadPage"
                >
                    <i class="far fa-redo-alt"></i>
                    {{ $t("system.new_version_reload_btn") }}
                </base-button>
                <base-button
                    v-if="false"
                    size="sm"
                    :outline="true"
                    type="primary"
                    class="add-shortcut"
                >
                    <i class="far fa-plus"></i>
                </base-button>

                <base-button
                    size="sm"
                    :outline="true"
                    type="primary"
                    @click="openSystemActivityModal"
                >
                    <i class="far fa-user-slash"></i>
                </base-button>

                <base-button
                    size="sm"
                    :outline="true"
                    type="primary"
                    v-if="false"
                >
                    <i class="far fa-receipt"></i>
                </base-button>

                <base-button
                    @click="openTravelModal"
                    size="sm"
                    :outline="true"
                    type="primary"
                >
                    <i class="far fa-car"></i>
                </base-button>

                <base-button
                    size="sm"
                    :outline="true"
                    type="primary"
                    @click="openTaskModal"
                >
                    <i class="far fa-clock"></i>
                    {{ $t("shortcut.register_time") }}
                </base-button>

                <a
                    v-if="getIsImpersonated"
                    class="btn btn-sm btn-default"
                    href="/v1/users/deimpersonate"
                >
                    <i class="far fa-arrow-left"></i>
                    {{ $t("shortcut.change_user_back") }}
                </a>
            </div>
        </div>
        <portal to="shortcut-modal">
            <v-system-activity-form
                v-if="showSystemActivityModal"
                :context="{ empty: true }"
                @close="closeSystemActivityModal"
                @save="saveSystemActivity"
            />
            <v-task-form
                v-if="showTaskModal"
                :context="{ empty: true }"
                @close="closeTaskModal"
                @save="taskSaved"
            />
            <v-travel-form
                v-if="showTravelModal"
                :context="{ empty: true }"
                :show-projects="true"
                @close="closeTravelModal"
                @close-refresh="travelSaved"
            />
        </portal>
    </div>
</template>

<style></style>
