<script>
import NumberParser from "@/libraries/NumberParser";

export default {
    components: {},
    props: {
        context: {
            type: Object,
            required: true,
        },
        numberStyle: {
            type: String,
            required: false,
            default: "decimal",
            description: "I18n number formatting preset (must be preconfigured!)"
        },
    },
    data() {
        return {
            decimal: "",
        };
    },
    watch: {
        decimal: function (v) {
            let parser = new NumberParser(this.$i18n.locale);
            let val = parser.parse(v);
            if (isNaN(val)) {
                this.context.model = "";
            } else {
                this.context.model = val;
            }
        },
    },
    created() {
        if (this.context.model !== "") {
            this.decimal = this.$n(this.context.model, this.numberStyle);
        }
    },
};
</script>

<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
    >
        <span class="vss-decimal">
            <input
                type="text"
                v-model="decimal"
                v-bind="context.attributes"
                autocomplete="no"
                @blur="context.blurHandler"
            />
        </span>
    </div>
</template>

<style lang="scss">
.input-group-text.vss-decimal {
    border: none !important;
    padding: 0 !important;

    input {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>