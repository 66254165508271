<script>
import Card from "./Card.vue";

export default {
    name: "NavStatsCard",
    components: {
        Card,
    },
    props: {
        type: {
            type: String,
            default: "default",
        },
        active: {
            type: Boolean,
            default: false,
        },
        disableRouteMatching: {
            type: Boolean,
            default: false
        },
        forceNavigation: {
            type: Boolean,
            default: false,
            description: "Force navigation even if active"
        },
        navigate: Object,
        icon: String,
        title: String,
        value: {},
        iconClasses: [String, Array],
    },
    data() {
        return {
            is_active: false,
        };
    },
    watch: {
        $route(to, from) {
            this.matchRouteActive();
        },
    },
    created() {
        
        this.is_active = this.active;
        if (!this.disableRouteMatching) {
            this.matchRouteActive();
        }        
    },
    methods: {
        matchRouteActive() {
            if (this.navigate) {
                if (this.$route.name == this.navigate.name) {
                    this.is_active = true;
                } else {
                    this.is_active = false;
                }
            }
        },
        doNavigation() {
            if (this.is_active == false || this.forceNavigation == true) {
                this.$router.push(this.navigate);
            }
        },
    },
};
</script>

<template>
    <card
        :class="{
            'card-stats card-nav': true,
            'active bg-gradient-mg': is_active,
            'mark-clickable': (is_active == false || forceNavigation == true) && navigate,
        }"
        :show-footer-line="true"
        @click="doNavigation"
    >
        <div class="row">
            <div class="col with-data">
                <slot>
                    <h5 class="card-title text-muted mb-0" v-if="title">
                        {{ title }}
                    </h5>
                    <template v-if="$slots.value">
                        <slot name="value"></slot>
                    </template>
                    <template v-else>
                        <div
                            :class="[`h1 font-weight-bold mb-0 text-${type}`]"
                            v-if="value || parseInt(value) === 0"
                        >
                            {{ value }}
                        </div>
                    </template>
                </slot>
            </div>

            <div class="col-auto with-icon" v-if="$slots.icon || icon">
                <slot name="icon">
                    <div
                        class="icon-area"
                        :class="[ iconClasses]"
                    >
                        <i :class="icon"></i>
                    </div>
                </slot>
            </div>
        </div>

        <p class="mt-3 mb-0 text-sm" v-if="$slots.footer">
            <slot name="footer"> </slot>
        </p>
    </card>
</template>