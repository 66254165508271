import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

const settingsMenu = {
    path: "/settings",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.settings")
    },
    children: [
        {
            path: "",
            name: "Settings",
            component: () => import("@/views/VPlaceholder"),
            meta: {
                title: i18n.t("title.settings")
            }
        },
        {
            path: "activities",
            name: "SettingsActivities",
            component: () =>
                import(
                    /* webpackChunkName: "settings" */ "@/views/Settings/Activities"
                ),
            meta: {
                title: i18n.t("title.activities")
            }
        },
        {
            path: "role-prices",
            name: "SettingsRolePrices",
            component: () =>
                import(
                    /* webpackChunkName: "settings" */ "@/views/Settings/RolePrices"
                ),
            meta: {
                title: i18n.t("title.role_prices")
            }
        },
        {
            path: "milestones",
            name: "SettingsMilestones",
            component: () =>
                import(
                    /* webpackChunkName: "settings" */ "@/views/Settings/VMilestones"
                ),
            meta: {
                title: i18n.t("title.milestones")
            }
        }
    ]
};

export default settingsMenu;
