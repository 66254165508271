<script>
export default {
    name: "VTable",
};
</script>

<template>
    <table v-on="$listeners">
        <slot />
    </table>
</template>
