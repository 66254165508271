<script>
import BaseSwitch from "@/components/BaseSwitch";
import Cloner from "@/libraries/Cloner";

export default {
    name: "FSwitch",
    components: {
        BaseSwitch,
    },
    props: {
        context: {
            type: Object,
            required: true,
        },
        onText: {
            type: String,
            default: "",
        },
        offText: {
            type: String,
            default: "",
        },
    },
    
    computed: {
        model: function () {
            if (this.context.model) {
                console.log("LOS MODELOS = 1", this.context.model);
                return 1;
            } else {
                console.log("LOS MODELOS = 0", this.context.model);
                return 0;
            }
        },
        yesText: function () {
            if (this.onText === "") {
                return this.$t('yes');
            } else {
                return this.onText;
            }
        },
        noText: function () {
            if (this.offText === "") {
                return this.$t('no');
            } else {
                return this.offText;
            }
        },
        bindAttrs: function () {
            let attrs = Cloner.clone(this.context.attributes);

            if ('class' in attrs) {
                delete attrs['class'];
            }

            return attrs;
        }
    },    
    methods: {
        processInput(inp) {
            console.log("INPUT", inp);
            this.context.model = inp;
        },
    },
    
};
</script>

<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
    >
        <base-switch
            v-bind="bindAttrs"
            @blur="context.blurHandler"
            @input="processInput"
            :value="model"
            :on-text="yesText"
            :off-text="noText"
            :use-numeric="true"
        ></base-switch>
    </div>
</template>

<style lang="scss">
.vfm-multiselect {
    border: none !important;
    //border: 1px solid red !important;
    padding: 0px !important;

    .vs__dropdown-toggle {
        border-radius: 4px !important;
        border-color: $border-color;
        height: 44px;
    }

    &.vs--open {
        .vs__dropdown-toggle {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
}
</style>