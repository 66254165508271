export default {
    // Custom validation for mday
    mday({value}) {        
        console.log("Formulate", value);
        if (value !== "") {
            let elems = value.split("-"); 
            let month = parseInt(elems[0]); 
            let day = parseInt(elems[1]); 

            if (month >= 1 && month <= 12 && day >= 1 && day <= 31) {
                return true; 
            } else {
                return false; 
            }
        } else {
            return true; 
        }        
    }, 
    isNormalInteger({value}) {
        var n = Math.floor(Number(value));
        return n !== Infinity && String(n) === value && n >= 0;
    },
    isPositiveInteger({value}) {
        var n = Math.floor(Number(value));
        return n !== Infinity && String(n) === value && n > 0;
    }
};