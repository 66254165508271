<script>
export default {
    name: "VEmpty",
    props: {
        type: {
            type: String,
            default: "span",
        },
        empty: {
            type: String,
            default: "---",
        },
        text: {
            default: "",
        },
        truncate: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        isTruncated() {
            if (this.truncate > 0 && this.text.length > this.truncate) {
                return true;
            } else {
                return false;
            }
        },
        truncatedText() {
            if (this.truncate > 0 && this.text.length > this.truncate) {
                return this.text.slice(0, this.truncate) + "...";
            }
            return this.text;
        },
    },
};
</script>

<template>
    <component :is="type">
        <template
            v-if="$slots.default && this.$slots.default[0].text.length > 0"
            ><slot
        /></template>
        <template v-else-if="text !== null && text.length > 0"
            >{{ truncatedText }}
            <span v-if="isTruncated" class="text-bold m-click" @click="$emit('expand')">{{
                $t("v-empty.read_all")
            }}</span></template
        >
        <template v-else>{{ empty }}</template>
    </component>
</template>

<style>
</style>
