<template>
    <div class="wrapper">
        <notifications></notifications>

        <default-menu />

        <div class="main-content">
            <dashboard-navbar></dashboard-navbar>    
            <div @click="$sidebar.displaySidebar(false)">
                <transition name="router" mode="out-in">
                    <!-- your content here -->
                    <router-view :key="$route.path"></router-view>
                </transition>
            </div>

            <transition name="fade">
                <portal-target name="modals"></portal-target>
            </transition>
            <transition name="fade">
                <portal-target name="shortcut-modal"></portal-target>
            </transition>
            <transition name="fade">
                <portal-target name="queue"></portal-target>
            </transition>

            <content-footer v-if="!$route.meta.hideFooter"></content-footer>
        </div>
    </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import DefaultMenu from "@/views/Layout/DefaultMenu";

function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

import DashboardNavbar from "./DefaultNavbar";
import ContentFooter from "./DefaultFooter";

export default {
    components: {
        DashboardNavbar,
        ContentFooter,
        DefaultMenu,
    },
    created() {
        this.initScrollbar();
    },    
    methods: {
        initScrollbar() {
            let docClasses = document.body.classList;
            let isWindows = navigator.platform.startsWith("Win");
            initScrollbar("scrollbar-inner");
            //docClasses.add("perfect-scrollbar-on");

            if (isWindows) {
                // if we are on windows OS we activate the perfectScrollbar function
                initScrollbar("scrollbar-inner");

                docClasses.add("perfect-scrollbar-on");
            } else {
                docClasses.add("perfect-scrollbar-off");
            }
        },
    },
};
</script>
