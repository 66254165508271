/** 
 * DayAttrs
 * Handler for date-picker and v-calendar 
 * @update:from-page -> 
 * this.highlightData = await DayAttrs.eventHandler(a);
 * and it works ;) 
 * 
 * 
 */
import dayjs from "dayjs";
let isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

import TaskService from "@/services/TaskService";


let instance = {

    async eventHandler(a) {
        return this.getAttrs(this.buildDateParams(a));
    },
    
    buildDateParams(a) {
        let dt = dayjs(`${a.year}-${a.month}-01`).format("YYYY-MM-DD");
        let params = {
            startDate: dt,
            endDate: dayjs(dt).endOf("month").format("YYYY-MM-DD"),
        };
        return params;
    },

    async getAttrs(params) {
        let days = []; 

        const r = await TaskService.GET_daysStatus(params);
        r.data.data.forEach((v, idx) => {
            let dayObj = {
                dot: {
                    class: v.is_closed ? "bg-warning" : "bg-success",
                },
                dates: new Date(v.task_date),
            };

            days.push(dayObj);
        });

        return days;
    }
};


export default instance;