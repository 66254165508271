<script>
import VSelect from "vue-select";

export default {
    components: {
        VSelect,
    },
    props: {
        context: {
            type: Object,
            required: true,
        },
        icon: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            isFocused: false,
            fullSelection: null,
        };
    },
    created() {
        // Loop options and assign full object for the internal vModel
        this.updateFullSelection();
    },
    computed: {
        model: {
            get: function () {
                return this.fullSelection;
            },
            set: function (v) {                
                this.updateReduceSelection(v);
            },
        },
        contextModel: function () {
            return this.context.model;
        },
    },
    watch: {
        contextModel: function (n) {
            this.updateFullSelection();
        },
    },
    methods: {
        updateFullSelection() {
            // Check if incoming is null or empty array.
            if (
                this.contextModel === null ||
                (Array.isArray(this.contextModel) &&
                    this.contextModel.length == 0)
            ) {
                this.fullSelection = null;
            } else {
                this.context.options.forEach((v, idx) => {
                    if (v.value == this.context.model) {
                        this.fullSelection = this.context.options[idx];
                    }
                });
            }
        },
        updateReduceSelection(inp) {
            // Our own "reduce" function

            if (Array.isArray(inp)) {
                console.log("Branch A");
                this.context.model = inp.map((a) => a.value);
            } else {
                console.log("Branch B", inp.value);
                this.context.model = inp.value;
            }
        },
        doBlur(e) {
            this.context.blurHandler(e);
            this.isFocused = false;
        },
    },
};
</script>

<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type} f-styled-select`"
        :data-type="context.type"
    >
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa-fw" :class="icon"></i></div>
            </div>

            <v-select
                class="f-styled-select__v_select"
                v-bind="context.attributes"
                v-model="model"
                :clearable="false"
                :options="context.options"
                @blur="doBlur"
                @focus="isFocused = true"
                autocomplete="no"
            >
                <div slot="no-options">
                    <div
                        class="eff-timer-selector-wrapper__element__spinner"
                    ></div>
                </div>

                <template #open-indicator="{ attributes }">
                    <span v-bind="attributes"
                        ><i class="fas fa-caret-down"></i
                    ></span>
                </template>
            </v-select>
        </div>
    </div>
</template>

<style lang="scss">
.f-styled-select {
    .input-group {
        .input-group-prepend {
            .input-group-text {
                border: none;
                padding: 0px 8px 0px 0px;
                i {
                    font-size: 20px !important;
                    color: $gray-600;

                }
            }
        }
    }

    &__v_select {
        &.form-control {
            border: none !important;
            padding: 0px !important;
            height: auto;            
        }

        
        .vs__actions {
            cursor: pointer;
            display: none;
        }


        .vs__dropdown-toggle {            
            border-radius: 6px !important;
            border-color: white !important;                                    
        }

        &.vs--open {
            .vs__dropdown-toggle {
                border-bottom: none;    
                border-bottom-left-radius: 0px !important;
                border-bottom-right-radius: 0px !important;
            }

        }

        .vs__dropdown-menu {
            border-color: $border-color !important;
            margin-top: -2px; 
            padding-top: 0px;
            box-shadow: none;
        }

        &:hover {
            .vs__dropdown-toggle {
                border-color: $border-color !important;
            }

            .vs__actions {
                display: block;
            }
        }
    }
}

.vss-multiselect {
    border: none !important;
    //border: 1px solid red !important;
    padding: 0px !important;

    .vs__dropdown-toggle {
        border-radius: 4px !important;
        border-color: rgb(229, 230, 231);
    }
}
</style>