import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

/* CUSTOMERS */
const customersMenu = {
    path: "/customers",
    name: "Customers",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.customers")
    },
    children: [
        {
            path: "index",
            name: "CustomersIndex",
            component: () =>
                import(
                    /* webpackChunkName: "customers" */ "@/views/Customers/VIndex"
                ),
            meta: {
                title: i18n.t("title.customers")
            }
        },
        {
            path: "manage/:customer_id",
            component: () =>
                import(
                    /* webpackChunkName: "customers" */ "@/views/Customers/VManage"
                ),
            props: true,
            children: [
                {
                    path: "cases",
                    name: "CustomersTabCases",
                    components: {
                        customer_tab: () =>
                            import(
                                /* webpackChunkName: "customers" */ "@/views/Customers/Tabs/VCases"
                            )
                    },
                    meta: {
                        title: i18n.t("title.cases")
                    },
                    props: {
                        customer_tab: true
                    }
                },
                {
                    path: "internal-time",
                    name: "CustomersTabInternalTime",
                    components: {
                        customer_tab: () =>
                            import(
                                /* webpackChunkName: "customers" */ "@/views/Customers/Tabs/VInternalTime"
                            )
                    },
                    meta: {
                        title: i18n.t("title.internal_time")
                    },
                    props: {
                        customer_tab: true
                    }
                },
                {
                    path: "internal-time-stats",
                    name: "CustomersTabInternalTimeStats",
                    components: {
                        customer_tab: () =>
                            import(
                                /* webpackChunkName: "customers" */ "@/views/Customers/Tabs/VInternalTimeStats"
                            )
                    },
                    meta: {
                        title: i18n.t("title.internal_time_statistics")
                    },
                    props: {
                        customer_tab: true
                    }
                },
                {
                    path: "old-contracts",
                    name: "OldCustomersTabContracts",
                    components: {
                        customer_tab: () =>
                            import(
                                /* webpackChunkName: "customers" */ "@/views/Customers/Tabs/VContracts"
                            )
                    },
                    meta: {
                        title: i18n.t("title.contracts")
                    },
                    props: {
                        customer_tab: true
                    }
                },
                {
                    // listing of new contracts
                    path: "subscriptions",
                    name: "CustomersTabSubscriptions",
                    components: {
                        customer_tab: () =>
                            import(
                                /* webpackChunkName: "customers" */ "@/views/Customers/Tabs/VSubscriptionList"
                            )
                    },
                    meta: {
                        title: i18n.t("title.subscriptions")
                    },
                    props: {
                        customer_tab: true
                    }
                },
                {
                    // listing of new contracts
                    path: "subscriptions/:subscription_id",
                    name: "CustomersTabSubscriptionDetails",
                    components: {
                        customer_tab: () =>
                            import(
                                /* webpackChunkName: "customers" */ "@/views/Customers/Tabs/VSubscriptionDetails"
                            )
                    },
                    meta: {
                        title: i18n.t("title.subscriptions")
                    },
                    props: {
                        customer_tab: true
                    }
                },
                // NEW CONTRACTS
                {
                    // listing of new contracts
                    path: "contracts",
                    name: "CustomersTabContracts",
                    components: {
                        customer_tab: () =>
                            import(
                                /* webpackChunkName: "customers" */ "@/views/Customers/Tabs/VNContracts"
                            )
                    },
                    meta: {
                        title: i18n.t("title.contracts")
                    },
                    props: {
                        customer_tab: true
                    }
                },
                // detaild view of a contract
                {
                    path: "contracts/:contract_id",
                    name: "CustomersTabManageContract",
                    components: {
                        customer_tab: () =>
                            import(
                                /* webpackChunkName: "customers" */ "@/views/NewContracts/VManageContract"
                            )
                    },
                    meta: {
                        title: i18n.t("title.contracts")
                    },
                    props: {
                        customer_tab: true
                    }
                },
                // END OF NEW CONTRACTS
                {
                    path: "invoices",
                    name: "CustomersTabInvoices",
                    components: {
                        customer_tab: () =>
                            import(
                                /* webpackChunkName: "customers" */ "@/views/Customers/Tabs/VInvoices"
                            )
                    },
                    meta: {
                        title: i18n.t("title.invoices")
                    },
                    props: {
                        customer_tab: true
                    }
                },
                {
                    path: "manage",
                    name: "CustomersManage",
                    components: {
                        customer_tab: () =>
                            import(
                                /* webpackChunkName: "customers" */ "@/views/Customers/Tabs/VOverview"
                            )
                    },
                    meta: {
                        title: i18n.t("title.overview")
                    },
                    props: {
                        customer_tab: true
                    }
                }
            ]
        },
        {
            path: "add",
            name: "CustomersAdd",
            component: () => import("@/views/VPlaceholder"),
            meta: {
                title: i18n.t("title.new_customer")
            }
        },
        {
            path: "settings",
            name: "CustomersSettings",
            component: () => import("@/views/VPlaceholder"),
            meta: {
                title: i18n.t("title.settings")
            }
        },
        {
            path: "import",
            name: "CustomersImport",
            component: () => import("@/views/Customers/VImport"),
            meta: {
                title: i18n.t("title.import_customers")
            }
        }
    ]
};

export default customersMenu;