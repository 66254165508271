import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

const startMenu = {
    path: "/",
    redirect: "/start",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.dashboard")
    },
    children: [
        {
            path: "start",
            props: true,
            name: "StartDashboard",
            component: () =>
                import(
                    /* webpackChunkName: "dashboard" */ "@/views/Dashboard/MomentumDashboard"
                ),
            meta: {
                title: i18n.t("title.dashboard")
            }
        },
        {
            path: "absence",
            props: true,
            name: "DashboardAbsence",
            component: () =>
                import(
                    /* webpackChunkName: "dashboard" */ "@/views/Dashboard/VAbsence"
                ),
            meta: {
                title: i18n.t("title.absence")
            }
        }
    ]
};

export default startMenu;