<script>
export default {
    props: {
        context: {
            type: Object,
            required: true,
        },
        icon: {
            type: String,
            default: "",
        },
    },
    computed: {
        model: function () {
            return this.context.model;
        },
    },
};
</script>

<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type} f-styled-input`"
        :data-type="context.type"
    >
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    <i class="fa-fw" :class="icon"></i>
                </div>
            </div>

            <input
                class="f-styled-input__input"
                v-bind="context.attributes"
                v-model="model"
                @blur="context.blurHandler"                
            />
        </div>
    </div>
</template>

<style lang="scss">
.f-styled-input {
    .input-group {
        .input-group-prepend {
            .input-group-text {
                border: none;
                padding: 0px 8px 0px 0px;
                i {
                    font-size: 20px !important;
                    color: $gray-600;
                }
            }
        }
    }

    &__input {
        margin-left: 0px;
        &.form-control {
            border: none !important;            
            padding: 0px 10px 0px 10px !important;
            border-radius: 6px !important;
            border-color: $border-color !important;
            height: 32px;
        }

        &.form-control:hover {            
            border: 1px solid $border-color !important;
            border-radius: 6px !important;
        }
    }
}
</style>