<script>
/**
 * Documentation: https://www.npmjs.com/package/vue2-datepicker
 */
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/da";
export default {
    props: {
        context: {
            type: Object,
            required: true
        },
        version: {
            type: String,
            default: "month"
        }
    },
    components: { DatePicker }
};
</script>

<template>
    <div
        :class="
            `formulate-input-element formulate-datepicker formulate-input-element--${context.type} formulate-date-picker-custom`
        "
        :data-type="context.type"
    >
        <date-picker
            :type="version"
            format="DD.MM.YYYY"
            value-type="YYYY-MM-DD"
            v-model="context.model"
            input-class="form-control"
            :clearable="false"
            :disabled="context.attributes.disabled"
        />
    </div>
</template>

<style lang="scss">
.formulate-datepicker {
    .mx-datepicker {
        width: 100% !important;
    }
}
</style>
