<script>
import QueueService from "@/services/QueueService";

export default {
    name: "VLoaderModal",
    props: {
        name: {
            default: "",
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<template>
    <modal
        size="lg"
        v-if="loading"
        :show="true"
        :showClose="false"
        class="queue-modal"
    >
        <template slot="default">
            <div class="queue-modal__container">
                <h3 class="text-center">{{ $t("vloader.work_in_progress") }}</h3>
                <v-loader :loading="loading" />

                <div v-if="name" class="queue-modal__container_name">
                    {{ name }}
                </div>
            </div>
        </template>
    </modal>
</template>

<style lang="scss">
.queue-modal {
    &__container {
        h3 {
            margin-bottom: 30px;
        }

        &_name {
            text-align: center;
            margin-top: 30px;
            padding-bottom: 15px;
        }
    }
}
</style>
