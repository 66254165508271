<script>
import VSelect from "vue-select";

export default {
    name: "FSelect",
    components: {
        VSelect,
    },
    props: {
        context: {
            type: Object,
            required: true,
        },
        noOptions: {
            default: "",
        },
    },
    computed: {
        model: function () {
            let idx = this.context.options.findIndex((v) => {
                return v.value == this.context.model ? true : false;
            });

            if (idx != -1) {
                return this.context.options[idx];
            } else {
                return this.context.model;
            }
        },
    },

    methods: {
        processInput(inp) {
            this.context.model = inp;
        },
        filterSelectable(opt) {
            if (opt.value == "__separator") {
                return false;
            } else {
                return true;
            }
        },
    },
};
</script>

<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
    >
        <v-select
            class="vfm-multiselect1"
            v-bind="context.attributes"
            :value="model"
            @input="processInput"
            :clearable="false"
            :options="context.options"
            :reduce="(v) => v.value"
            @blur="context.blurHandler"
            autocomplete="no"
            :selectable="filterSelectable"
        >
            <div slot="no-options">
                <div class="text-warning">
                    <i class="far fa-exclamation-triangle"></i>

                    <template v-if="noOptions !== ''">
                        {{ noOptions }}
                    </template>
                    <template v-else>{{
                        $t("fselect.no_options_available")
                    }}</template>
                </div>
            </div>
        </v-select>
    </div>
</template>

<style lang="scss">
.vfm-multiselect {
    border: none !important;
    //border: 1px solid red !important;
    padding: 0px !important;

    .vs__dropdown-toggle {
        border-radius: 4px !important;
        border-color: rgb(229, 230, 231);
        height: 44px;
    }

    &.vs--open {
        .vs__dropdown-toggle {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
}
</style>
