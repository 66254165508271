<script>
export default {
    name: 'VTfoot'
}
</script>

<template>
    <tfoot v-on="$listeners">
        <slot />
    </tfoot>
</template>
