import axios from 'axios';

let instance = null;

if (!instance) {
    instance = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        withCredentials: true,
        maxContentLength: 999999,
        timeout: 30000,
    });
}

export default instance;
