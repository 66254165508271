<script>
export default {
    name: 'VTbody'
}
</script>

<template>
    <tbody v-on="$listeners">
        <slot />
    </tbody>
</template>
