import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

const usersMenu = {
    path: "/users",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.coworkers")
    },
    children: [
        {
            path: "index",
            name: "UsersIndex",
            component: () =>
                import(/* webpackChunkName: "users" */ "@/views/Users/VIndex"),
            meta: {
                title: i18n.t("title.coworkers")
            }
        }
    ]
};

export default usersMenu;