<template>
    <div
        class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-dark bg-default"
        @mouseenter="$sidebar.onMouseEnter()"
        @mouseleave="$sidebar.onMouseLeave()"
    >
        <div class="scrollbar-inner" ref="sidebarScrollArea">
            <div class="sidenav-header d-flex align-items-center">
                <a class="navbar-my-profile" href="#">
                    <v-loader :loading="true" v-if="authUser === null" />
                    <div
                        class="profile-data"
                        @click="$router.push({ name: 'MyAccountPage' })"
                        v-else
                    >
                        <div class="profile-left">
                            <div
                                class="avatar avatar-xs rounded-circle profile-avatar"
                            >
                                <img :src="userAvatar" v-if="userAvatar" />
                                <i v-else class="far fa-user-circle"></i>
                            </div>
                        </div>
                        <div
                            class="profile-right"
                            v-if="
                                $sidebar.isMinimized == false ||
                                    $sidebar.hovered == true
                            "
                        >
                            <div class="profile-name">{{ authUser.name }}</div>
                            <!--
              <div class="profile-name">Thomas</div>
              <div class="profile-subtitle">Administrator</div>
              -->
                        </div>
                    </div>
                </a>

                <div class="ml-auto">
                    <!-- Sidenav toggler -->
                    <div
                        class="sidenav-toggler d-none d-xl-block"
                        :class="{ active: !$sidebar.isMinimized }"
                        @click="minimizeSidebar"
                    >
                        <div class="sidenav-toggler-inner">
                            <i class="sidenav-toggler-line"></i>
                            <i class="sidenav-toggler-line"></i>
                            <i class="sidenav-toggler-line"></i>
                        </div>
                    </div>
                </div>
            </div>

            <slot></slot>
            <div class="navbar-inner">
                <ul class="navbar-nav">
                    <slot name="links">
                        <sidebar-item
                            v-for="(link, index) in sidebarLinks"
                            :key="link.name + index"
                            :link="link"
                        >
                            <sidebar-item
                                v-for="(subLink, index) in link.children"
                                :key="subLink.name + index"
                                :link="subLink"
                            >
                            </sidebar-item>
                        </sidebar-item>
                    </slot>
                </ul>
                <slot name="links-after"></slot>
            </div>
            <!-- .navbar-inner srcset="/img/logo/mg-white.png 1x, /img/logo/MG-logo@2x.png 2x" srcset="/img/logo/m-white.png 1x, /img/logo/M-logo@2x.png 2x"  -->
        </div>
        <div class="navbar-bottom-logo">
            <img
                src="/img/logo/meg-white.png"
                style="max-width: 190px"
                v-if="$sidebar.isMinimized == false || $sidebar.hovered == true"
            />
            <img
                src="/img/logo/meg-logo-white.png"
                style="max-width: 22px"
                v-else
            />
        </div>
        <!-- .scrollbar-inner -->
    </div>
    <!-- .navbar -->
</template>
<script>
import { mapState, mapGetters } from "vuex";

export default {
    name: "sidebar",
    props: {
        title: {
            type: String,
            default: "Creative Tim",
            description: "Sidebar title"
        },
        shortTitle: {
            type: String,
            default: "CT",
            description: "Sidebar short title"
        },
        logo: {
            type: String,
            default:
                "https://demos.creative-tim.com/vue-argon-dashboard-pro/img/brand/green.png",
            description: "Sidebar app logo"
        },
        sidebarLinks: {
            type: Array,
            default: () => [],
            description:
                "List of sidebar links as an array if you don't want to use components for these."
        },
        autoClose: {
            type: Boolean,
            default: true,
            description:
                "Whether sidebar should autoclose on mobile when clicking an item"
        }
    },
    provide() {
        return {
            autoClose: this.autoClose
        };
    },
    computed: {
        ...mapState("auth", ["authUser"]),
        ...mapGetters("auth", ["userAvatar"])
    },
    created() {
        //console.log(this.authUser);
    },
    methods: {
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        }
    },
    mounted() {
        this.$sidebar.isMinimized =
            this.$sidebar.breakpoint < window.innerWidth;
        this.minimizeSidebar();
    },
    beforeDestroy() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    }
};
</script>
