<template>
    <div>
        <!-- Header -->
        <div class="header bg-gradient-mg py-7 py-lg-8 pt-lg-9">
            <div class="container">
                <div class="header-body text-center mb-7">
                    <div class="row justify-content-center">
                        <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                            <h1 class="text-light text-404">404</h1>
                            <h1 class="text-light">
                                {{ $t("not_found.not_found") }}
                            </h1>
                            <p class="text-lead text-white">
                                {{ $t("not_found.not_found_text") }}
                            </p>

                            <router-link
                                to="/dashboard"
                                class="font-weight-bold text-white mt-5"
                                >{{ $t("not_found.back_to_main") }}</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="separator separator-bottom separator-skew zindex-100">
                <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon
                        class="fill-default"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div>
        </div>
        <div class="bottom-image">
            <div class="text-center">
                <img src="/img/logo/mg-white-x2.png" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "not-found",
};
</script>

<style lang="scss">

.text-404 {
    font-size: 90px;
    text-shadow: 5px 5px gray;
}

.bottom-image {
    position: absolute;
    width: 100%;
    bottom: 0;    
    height: 300px;
    
    background: $default;
    margin: 0;
    div {
        margin-top: 40px; 
    }
}
</style>